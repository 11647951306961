
export default function Button({ title, url, onClick, className }) {

    //console.log("BUTTON::", className)
    return (
        <div className="flex py-3 w-full text-center md:w-72 max-w-full">
            <div className={"flex w-full justify-center rounded-2xl //shadow-sm bg-slate-700 " + className} onClick={onClick}>
                <a className={`
                        rounded-2xl
                        w-full
                        max-w-full
                        border-t border-l border-t-white/20 border-l-white/20
                        border-b border-r border-b-black/10 border-r-black/10
                        hover:bg-black/10
                        font-bold
                        cursor-default
                        //bg-red-200
                        `
                    }
                    href={url}
                    target="_blank"
                >
                    <span className="">
                        { title}
                    </span>
                </a>
            </div>
        </div>
    )
}