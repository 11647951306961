import { useEffect, useState } from "react"
import useData from "../../vio/context/useData"
import Waiting from "./Waiting"
import Config from "./Config"
import Plugins from "./Plugins"
import MessageContent from "./MessageContent"
import updater from "../../vio/context/updater"
import { getObjectStorage, getStorage } from "../../commons/store"

export default function Message({ id=0 }) {

    let [ userName ] = useData("userName", `Widget${id}`)
    let [ sum, setSum ] = useState(false)
    let [a] = useData(`messages`, "Messagemessages" + id)
    let [{ user, message, state, cost, config, plugins, emoticon } = {}] = useData(`messages/${id}`, "Message" + id)
    let caption = "", text = ""        

    //console.log("MESSAGE::", { message } )

    useEffect(() => {
        var div = document.getElementById("conversation");
        div?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        if(state === "close" && sum === false) {
            updater(`usageAmount`)(Number(cost), "s")

            if(user === "assistant" && Number(cost) > 0) {
                //console.log(">>MSG::", id/2, cost)
                updater(`counterMessages`)(1, "s")
                setSum(true)

                //updater(`counterMessages`)(1, "s")
                //updater("avatar")({ maximized: false })
            }        
        }

        if(userName === undefined) {
            let name = getStorage("userName") || "Tú"
            updater("userName")(name, "r")
        }
    })

    if (state === undefined) {

        return null

    } else if (state === "waiting") {

        text = <Waiting />

    } else if (state === "writing" || state === "ready") {

        text = <MessageContent id={id} sender={user} message={message} state={state}  />

    } else if (state === "close") {

        text = <MessageContent id={id} sender={user} message={message} state={state}  />
    }

    let lang = config

    return (
        <>
            <MessageBubble
                key={"bubble:"+id}
                text={text}
                caption={caption}
                sender={user}
                cost={cost}
                name={user === "user" ? userName : "Charls"}
                language={lang}
                emoticon={emoticon}
            />
            <Message id={id + 1} key={"msg:"+id} />
        </>
    )
}

const MessageBubble = ({ name="", sender, caption, text="", cost=0, language, emoticon }) => {
    //const bgColor = sender === 'user' ? '' : '//bg-slate-800 //bg-gray-800';
    //const bubbleAlignment = sender === 'user' ? 'mt-1 //!bg-amber-400 //border //border-amber-400 text-black font-bold' : ' mt-1 //!bg-slate-700 //border //border-slate-600';
    //let avatar = sender === 'user' ? "U" : "C"

    //let userSrc = "https://images.pexels.com/photos/365638/pexels-photo-365638.jpeg?auto=compress&cs=tinysrgb&w=75"
    //let src =  "https://images.pexels.com/photos/2127969/pexels-photo-2127969.jpeg?auto=compress&cs=tinysrgb&w=75"
    //https://images.pexels.com/photos/1555313/pexels-photo-1555313.jpeg?auto=compress&cs=tinysrgb&w=75

    let user = "https://images.pexels.com/photos/365638/pexels-photo-365638.jpeg?auto=compress&cs=tinysrgb&w=75"
    //let assistant =  "https://images.pexels.com/photos/2127969/pexels-photo-2127969.jpeg?auto=compress&cs=tinysrgb&w=75"
    let assistant = 'asisstant.png'

    let { src, className } = sender === 'user' ? { src: user, className: 'items-end', } : { src: assistant, className: ' items-start', }

    let { lang, country } = language || {}
    let isUser = sender === 'user'
    let isAssistant = sender === 'assistant'

    //console.log(">> BUBBLE::", language)

    return(
        <div className={`relative mb-3 md:mb-4 flex flex-col //bg-white ${className}`}>
            {
                (language) &&
                <div className="w-full pl-10 pb-3 md:pb-4 //bg-red-400">
                    <Config lang={lang} country={country} />
                </div>
            }
            <div className="flex items-start gap-2">
                { isAssistant && <div className="flex gap-1.5 items-center //bg-red-400">
                    {
                        isAssistant &&
                        <div key="avatar" className={`flex flex-col items-center justify-center flex-shrink-0 h-8 w-8 md:h-8 md:w-8 rounded-full //p-0.5 bg-white/5 text-center text-xs`}>
                            {   src && <img src={src} alt="Avatar" className="w-full h-full object-cover rounded-full" style={{ objectPosition: 'center center' }} /> }
                        </div>
                    }
                    </div>
                }
                <span className={`relative rounded-2xl //p-3 ${isUser ? " ml-11 rounded-tr-md !text-white //bg-blue-300":" //mr-11 rounded-tl-md text-black font-light //bg-white"}`}>
                    <span className={`!bg-red-400`}>
                        { text }
                    </span>
                    {
                        (isUser && emoticon) &&
                        <Emoticon emoticon={emoticon} />
                    }
                </span>
                { false && <div className="flex gap-1.5 items-center //bg-red-400">
                    {
                        isUser &&
                        <div key="avatar" className={`flex items-center justify-center flex-shrink-0 h-8 w-8 md:h-8 md:w-8 rounded-full //p-0.5 bg-white/5 text-center text-xs`}>
                            {   src && <img src={src} alt="Avatar" className="w-full h-full object-cover rounded-full" style={{ objectPosition: 'center center' }} /> }
                        </div>
                    }
                    </div>
                }
                {   false && <span className="absolute -bottom-0.5 right-3 text-[11px] text-gray-400 font-thin">
                        { cost > 0 && cost}
                    </span>
                }
            </div>
        </div>
    )
};

const Emoticon = ({ emoticon }) => {
    const [isVisible, setIsVisible] = useState(false);
    let [[animation, duration]] = useState(getRandomAnimation())

    useEffect(() => {

        // Inicia el temporizador para aplicar la escala después de 2 segundos
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, duration); // 2000 ms = 2 segundos
  
        // Limpia el temporizador si el componente se desmonta
        return () => clearTimeout(timer);
    }, []);

  
    return (
      <span
        className={`absolute -bottom-2.5 right-3  ${
          isVisible ? 'text-xl' :  " text-xl " + animation
        }`}
      >
        {emoticon}
      </span>
    );
  };

  let animates = [
    ['animate-bounce', 2400],
    //['animate-pulse', 2400],
    ['animate-spin', 1000],
    ['animate-ping', 2000],
  ];

  function getRandomAnimation() {
    // Obtener un índice aleatorio entre 0 y la longitud del array menos 1
    const randomIndex = Math.floor(Math.random() * animates.length);
    
    //return ['animate-ping', 2000]
    // Devolver el elemento en el índice aleatorio
    return animates[randomIndex];
  }
  
  // Definir el array de animaciones
  
function getRandomTailwindAnimate() {
    let animates = [
        ['animate-bounce', 2400],
        ['animate-pulse', 2400],
        ['animate-spin', 2400],
        ['animate-reverse-spin', 2400],
        ['animate-ping', 2400],
    ]

    return animates[Math.floor(Math.random() * 5) + 1]

}

/*
        return (
            <>
                <Config {...config} />
                <Waiting />
            </>
        )
return (
    <>
        <Config {...config} />
        <div key="main" className={tw.list + " flex items-center text-md " + (isAssistant ? '' : ' justify-end ')}>
            <div key="wrap" className={"flex flex-col items-end mb-2 p-1 px-4 " + (isAssistant ? tw.left : tw.right)}>
                <div className="">
                    <MessageContent message={message} state={state} />
                    { (isAssistant && cost) && (
                        <div key="plugins" className={"relative flex justify-start mt-2 pt-1 border-gray-700 items-center gap-2 " + (pluginsEnabled ? "border-t":"")}>
                            { ( true || pluginsEnabled > 0) && <Plugins items={plugins} /> }
                            { cost &&
                                <span key="cost" className={ "absolute text-xs text-gray-400 right-0 " + (pluginsEnabled ? "bottom-1.5":"-bottom-0.5") } >
                                    $ { cost }
                                </span>
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
)
*/