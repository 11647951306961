let isIPhone = () => {

    return /iPhone/.test(navigator.userAgent);
}

let isMac = () => {

    return /Macintosh/.test(navigator.userAgent);
}

let addClass = (element, className) => {
    if(element) {
        element.classList.add(className);
    }
}

let removeClass = (element, className) => {
    if(element) {
        element.classList.remove(className);
    }
}

let addEventListener = (element, event, handler) => {
    if(element) {
        element.addEventListener(event, handler);
    }
}

export { isIPhone, isMac, addClass, removeClass, addEventListener };