function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function alltrim(str) {

    return str ? str.replace(/^\s+|\s+$/gm, '').replace(/\s+/g, ' ') : ""
}

function trim(str) {

    return str ? str.replace(/^\s+|\s+$/g, '') : ""
}

export { alltrim, trim, capitalizeFirstLetter }