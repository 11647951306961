import React from 'react';
import './firefly.css';

const Firefly = ({ items }) => {

    return [...Array(items)].map((_, index) => (
        <FireflyItem key={index} index={index} />
    ))
}

const FireflyItem = ({ index }) => {
  const rotationSpeed = `${Math.floor(Math.random() * 10) + 8}s`;
  const steps = Math.floor(Math.random() * 12) + 16;

  // Función para generar una ubicación aleatoria en la pantalla
  const randomLocation = () => {
    const x = Math.random() * window.innerWidth;
    const y = Math.random() * window.innerHeight;
    return { x, y };
  };

  // Ubicación inicial aleatoria
  const initialLocation = randomLocation();

  return (
    <div className="firefly" style={{ animationName: `move${index}`, position: 'fixed', left: initialLocation.x, top: initialLocation.y, width: '0.4vw', height: '0.4vw', pointerEvents: 'none' }}>
      <div className="before" style={{ animationDuration: rotationSpeed }}></div>
      <div className="after" style={{ animationDuration: `${rotationSpeed}, ${Math.floor(Math.random() * 6000) + 5000}ms`, animationDelay: `0ms, ${Math.floor(Math.random() * 8000) + 500}ms` }}></div>
    </div>
  );
};

export default Firefly;