import getSocket from "../vio/context/getSocket";
import { getProfile } from './profile';
import updater from "../vio/context/updater";
import getState from '../vio/context/getState'
import { nanoid } from 'nanoid'
import { getObjectStorage } from "./store";
var first = true

function send(msg, type="ask") {

    let { emit } = getSocket('assistant')
    let message = msg.trim()

    let id = insertQuestion(message)

    let prompt = getPrompt(message, id, type)

    prompt &&
        emit('private', prompt)
}

function insertQuestion( message ){

    setMessage("user", message, "ready", "-")
    let id = setMessage("assistant", "", "waiting", "--")
    updater(`timer`)({ active: true, time: 0 })

    if (first) {
        first = false
        updater("avatar")({ maximized: false })
    }

    return id
}

function setMessage(user, message, state, caption = "", config = "") {

    let id = createMessageId()

    //console.log({ id, user, message, state, caption, config })

    updater(`messages/${id}`)({ user, message, state, caption, config })

    return id
}

function updateMessage({ id, ...props }) {

    //console.log("updateMessage", `messages/${id}`, { id, ...props })
    updater(`messages/${id}`)({ ...props })

    if(props.state === "close") {
        saveConversation()
    }
}

function getPrompt(message, id, type="ask") {

    const messages = getState("messages")

    if (message === "reset") {

        message = "hola"
        reset()
        return false
    }

    let { length } = messages
    let conversation = messages.slice(-Math.min(length, 41), -1).map(({ user, message }) => ({
        role: user,
        content: `${message}`,
    }))

    //console.log("getPrompt", message, id, type)

    let user_id = localStorage.getItem('user_id');

    if(user_id === null || user_id === undefined) {
        user_id = nanoid() //=> "V1StGXR8_Z5jdHi6B-myT"
        localStorage.setItem('user_id', user_id);
    }

    let session_id = localStorage.getItem('session_id');

    if(session_id === null || session_id === undefined) {
        session_id = nanoid() //=> "V1StGXR8_Z5jdHi6B-myT"
        localStorage.setItem('session_id', session_id);
    }

    let shortcuts = getObjectStorage('shortcuts');

    /*
    console.log("SENDING:", {
        id,
        user_id,
        session_id,
        shortcuts,
        message
    })
    */
    return {
        id,
        user_id,
        session_id,
        shortcuts,
        message,
        type,
        conversation,
        settings: getProfile(),
    }
}

function getInitialMessage() {

    const n = getProfile().name

    /*
    const data = n !== null & n !== undefined
        ? [
            { user: 'assistant', message: `Hola, ${n}. ¿En qué te puedo ayudar hoy?`, state: "ready" },
        ] : [
            { user: 'assistant', message: "👋 Hola, mi nombre es Charls 🤖. Soy el Asistente de IA del Hotel Quinta Bella🏝️", state: "ready" },
            { user: 'assistant', message: "Para darte un mejor servicio, ¿me puedes decir tu nombre?", state: "ready" },
        ]
*/

        const data =[
            //{ user: 'assistant', message: "¡Hola! 👋 mi nombre es Charls 🤖. Soy el Asistente de IA del Hotel Quinta Bella🏝️\nPara darte un mejor servicio, ¿me puedes decir tu nombre?", state: "close" },
            { user: 'assistant', message: "Hi! 👋 My name is Charls. I am the AI Assistant at Quintabella Hotel🏝️\nTo provide you with better service, could you please tell me your name?", state: "close" },
        ]

    return data
}

function createMessageId() {

    //return nanoid()
    return getState("messages").length
}

function saveConversation() {

    localStorage.setItem("conversation", JSON.stringify(getState("messages")))
}

function getConversation() {

    let conversation = localStorage.getItem("conversation")

    conversation = conversation
        ? JSON.parse(conversation)
        : getInitialMessage()

    return conversation
}

function reset() {

    localStorage.removeItem("profile")
    localStorage.removeItem("userName")
    localStorage.removeItem("preferences")
    localStorage.removeItem("lang")
    localStorage.removeItem("conversation")
    //localStorage.removeItem("session_id")
    window.location.reload();
}

export { getInitialMessage, send, updateMessage, getConversation, reset }