import { useEffect, useState } from "react"
import useData from "../../vio/context/useData"

export default function Waiting({ onTimeout, onIntent }) {

    let timer = 15
    let limit = 2
    let [count, setCount] = useState(timer)
    let [intent, setIntent] = useState(0)
    let [warning, setWarning] = useState("")

    useEffect(() => {
        if(intent >= 0) {
            const interval = setInterval(() => {
                setCount(prevCount => prevCount - 1)
            }, 1000)

            
            return () => {
                clearInterval(interval)
            }
        }
    }, [intent])

    useEffect(() => {
        if (count === -1) {
            if(intent === limit) {
                setWarning(`(Tiempo Excedido)`)
                onTimeout && onTimeout()
                setIntent(false)
            } else {
                let times = intent + 1
                setCount(timer)
                setIntent(times)
                onIntent && onIntent(times)
            }
        }
    }, [count])

    return (
        warning ? (
            <div className="text-xs text-gray-400 font-medium">{ warning }</div>
        ) : (
            <div className="flex //flex-col justify-center items-center">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                { (count < (timer - 7) || intent > 0) && <div className=" px-2 text-center rounded-sm //bg-white/20 font-thin text-xs">{`${intent + 1} → ${count}`}</div> }
            </div>
        )
    )
}
