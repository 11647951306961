import { useRef, useEffect, useState } from 'react';
import { register } from 'swiper/element/bundle';
import { FullScreenIcon } from '../../components/widget/Icons';

register();

function SwiperSlider({ items }) {
    const swiperElRef = useRef(null);

    useEffect(() => {
        // Escucha los eventos de Swiper usando addEventListener
        /*swiperElRef.current.addEventListener('progress', (e) => {
            const [swiper, progress] = e.detail;
            //console.log(progress);
        });

        swiperElRef.current.addEventListener('slidechange', (e) => {
            //console.log('Slide cambiado');
        });
        */
    }, []);

    return (
        <ImageGallery items={items} />
    )

    /*
    return (
            <swiper-container
                ref={swiperElRef}
                slides-per-view="1"
                navigation="true"
                pagination="true"
                class='swiper-container'
            >
                {items.map(( item, ind ) =>{

                    //console.log("ITEM", item, ind)
                    return (
                        <swiper-slide key={ind}>
                            { item }
                        </swiper-slide>
                    )
                }
                )}
            </swiper-container>

    );
    */
}

//<div className=' //mx-28 //md:w-96 rounded-2xl //border-2 bg-green-400 '>
//</div>

function ImageGallery({ items }) {
    const [current, setCurrent] = useState(0);
    const [fade, setFade] = useState(false);
  
    const onClick = (index) => {
      setFade(true);
      setTimeout(() => {
        setCurrent(index);
        setFade(false);
      }, 300); // Duración de la transición en ms
    };

    let maxLength = 5

    let length = items.length > maxLength ? maxLength : items.length;

    let a = "w-1/2 w-1/3 w-1/4 w-1/5 w-1/6"

    return (
        <div className='flex justify-center md:justify-start //w-full //bg-white //border-t-4 //border-blue-100 '>
                <div className="flex flex-col items-center justify-center w-full md:w-1/2 gap-1 p-0 md:p-2 bg-white //md:gap-1 flex-wrap border-gray-200 border-0 rounded-tl-sm rounded-xl md:rounded-2xl  md:rounded-tl-md">
            <div className='flex flex-col gap-1 rounded-tl-sm rounded-xl md:rounded-2xl  md:rounded-tl-md overflow-hidden'>
                    <div className='bg-gray-100 '>
                        <div className={`relative w-full border-white transition-opacity duration-300  ${fade ? 'opacity-0' : 'opacity-100'}`}>
                            { items[current]}
                            { false && <FullScreenIcon className="absolute top-2 right-2"/> }
                        </div>
                    </div>
                    { items.length > 1 && <div className={`relative flex justify-center items-center gap-1`}>
                        {items.slice(0, maxLength).map((item, index) => (
                            <div key={index}
                                className={` w-1/${length} border-t-blue-500 ${index === current ? '-mt-1 border-t-4' : ''}`}
                                    onClick={() => onClick(index)}>
                                { item }
                            </div>
                        ))}
                            { (false && items.length > maxLength) && <div className="flex px-2 justify-center items-center h-full bg-black  text-white font-normal text-center text-lg ">
                                {`${items.length - maxLength}+`}
                            </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
  };

export default SwiperSlider