const FacebookIcon = () => {
    return (
        <svg
            viewBox="-5 0 20 20"
            version="1.1"
            className="w-8 h-8 text-white"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>facebook [#176]</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -7399.000000)" fill="#ffffff">
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path
                            d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                            id="facebook-[#176]"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const BookIcon = ({color="#000000"}) => {
    return (
        <svg
            className="w-8 h-8 text-black"
            viewBox="0 0 24 24"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 19.25C1.58579 19.25 1.25 19.5858 1.25 20C1.25 20.4142 1.58579 20.75 2 20.75V19.25ZM22 20.75C22.4142 20.75 22.75 20.4142 22.75 20C22.75 19.5858 22.4142 19.25 22 19.25V20.75ZM4.97222 20C4.97222 16.1687 8.11154 13.05 12 13.05V11.55C7.29736 11.55 3.47222 15.3261 3.47222 20H4.97222ZM12 13.05C15.8885 13.05 19.0278 16.1687 19.0278 20H20.5278C20.5278 15.3261 16.7026 11.55 12 11.55V13.05ZM9.97222 11.475C9.97222 11.0646 10.1898 10.6401 10.5942 10.2963C10.9999 9.95147 11.5258 9.75 12 9.75V8.25C11.1337 8.25 10.2707 8.60258 9.62263 9.15348C8.97322 9.70554 8.47222 10.5185 8.47222 11.475H9.97222ZM12 9.75C12.4742 9.75 13.0001 9.95147 13.4058 10.2963C13.8102 10.6401 14.0278 11.0646 14.0278 11.475H15.5278C15.5278 10.5185 15.0268 9.70554 14.3774 9.15348C13.7293 8.60258 12.8663 8.25 12 8.25V9.75ZM14.0278 11.475V12.85H15.5278V11.475H14.0278ZM8.47222 11.475V12.85H9.97222V11.475H8.47222ZM2 20.75H22V19.25H2V20.75Z"
                fill={color}
            />
            <path
                d="M12 5V4M16.5 6.06219L15.5622 7M8.43781 7L7.5 6.06218"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const YouTubeIcon = () => {
    return (
        <svg
            fill="#ffffff"
            className="w-8 h-8"
            viewBox="0 -4 32 32"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
        >
            <path d="M30.722,20.579 C30.137,21.894 28.628,23.085 27.211,23.348 C27.066,23.375 23.603,24.000 16.010,24.000 L15.990,24.000 C8.398,24.000 4.932,23.375 4.788,23.349 C3.371,23.085 1.861,21.894 1.275,20.578 C1.223,20.461 0.001,17.647 0.001,12.000 C0.001,6.353 1.223,3.538 1.275,3.421 C1.861,2.105 3.371,0.915 4.788,0.652 C4.932,0.625 8.398,-0.000 15.990,-0.000 C23.603,-0.000 27.066,0.625 27.210,0.651 C28.628,0.915 30.137,2.105 30.723,3.420 C30.775,3.538 32.000,6.353 32.000,12.000 C32.000,17.647 30.775,20.461 30.722,20.579 ZM28.893,4.230 C28.581,3.529 27.603,2.759 26.845,2.618 C26.813,2.612 23.386,2.000 16.010,2.000 C8.615,2.000 5.185,2.612 5.152,2.618 C4.394,2.759 3.417,3.529 3.104,4.234 C3.094,4.255 2.002,6.829 2.002,12.000 C2.002,17.170 3.094,19.744 3.106,19.770 C3.417,20.471 4.394,21.241 5.153,21.382 C5.185,21.388 8.615,22.000 15.990,22.000 L16.010,22.000 C23.386,22.000 26.813,21.388 26.846,21.382 C27.604,21.241 28.581,20.471 28.894,19.766 C28.904,19.744 29.998,17.170 29.998,12.000 C29.998,6.830 28.904,4.255 28.893,4.230 ZM13.541,17.846 C13.379,17.949 13.193,18.000 13.008,18.000 C12.842,18.000 12.676,17.959 12.525,17.875 C12.206,17.699 12.008,17.364 12.008,17.000 L12.008,7.000 C12.008,6.637 12.204,6.303 12.521,6.127 C12.838,5.950 13.227,5.958 13.534,6.149 L21.553,11.105 C21.846,11.286 22.026,11.606 22.027,11.951 C22.028,12.296 21.852,12.618 21.560,12.801 L13.541,17.846 ZM14.009,8.794 L14.009,15.189 L19.137,11.963 L14.009,8.794 Z" />
        </svg>
    );
};

const TwitterIcon = () => {
    return (
        <svg
            className="w-8 h-8 text-white"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 118.98 107.54"
            fill="#ffffff"
        >
            <path
                d="M290.21,202.23h18.24L268.6,247.78l46.89,62H278.77L250,272.18l-32.9,37.6H198.86L241.49,261l-45-58.82h37.65l26,34.36Zm-6.4,96.62h10.11l-65.25-86.28H217.82Z"
                transform="translate(-196.51 -202.23)"
            />
        </svg>
    );
};

const PanoramicIcon = () => {
    return (
        <svg className="w-8 h-8" style={{ verticalAlign: 'middle', fill: 'white', overflow: 'hidden' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M280 65.312l-29.76 7.392C120.96 104.736 40 158.944 40 227.2c0 68.256 80.96 122.464 210.24 154.496C326.464 400.832 417.088 411.2 512 411.2c94.912 0 185.536-10.368 261.856-29.536C903.008 349.664 984 295.456 984 227.2S903.04 104.736 773.76 72.704L744 65.344v274.08C675.456 354.848 595.584 363.2 512 363.2c-83.616 0-163.52-8.32-232-23.744V65.28z m512 62.08c91.36 27.136 144 65.6 144 99.808 0 34.24-52.64 72.672-144 99.84V127.36zM88 227.2c0-34.24 52.64-72.672 144-99.84v199.68c-91.36-27.2-144-65.6-144-99.84z" fill="white" />
            <path d="M936 227.2v512c0 68.352-189.408 136-424 136s-424-67.648-424-136v-512H40v512c0 108.288 211.904 184 472 184s472-75.712 472-184v-512h-48z" fill="white" />
            <path d="M423.52 787.136l111.744-167.584 214.144 262.816 37.184-30.336-255.168-313.216-115.84 173.76-116.704-113.12-158.4 221.792 39.04 27.904 126.016-176.416zM856 515.2a88 88 0 1 1-176.032-0.064A88 88 0 0 1 856 515.2z m-48 0a40 40 0 1 0-80.032 0.032A40 40 0 0 0 808 515.2z" fill="white" />
        </svg>
    );
};

const TripAdvisorIcon = ({color="#000000"}) => {
    return (
        <svg fill={color} className="w-8 h-8" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6.009h-2.829C15.211 4.675 12.813 4 10 4s-5.212.675-7.171 2.009H0c.428.42.827 1.34.993 2.04A4.954 4.954 0 0 0 0 11.008c0 2.757 2.243 5 5 5a4.97 4.97 0 0 0 3.423-1.375L10 17l1.577-2.366A4.97 4.97 0 0 0 15 16.01c2.757 0 5-2.243 5-5 0-1.112-.377-2.13-.993-2.96.166-.7.565-1.62.993-2.04zm-15 8.4c-1.875 0-3.4-1.525-3.4-3.4s1.525-3.4 3.4-3.4 3.4 1.525 3.4 3.4-1.525 3.4-3.4 3.4zm5-3.4a5.008 5.008 0 0 0-4.009-4.9C7.195 5.704 8.53 5.5 10 5.5s2.805.204 4.009.61A5.008 5.008 0 0 0 10 11.008zm5 3.4c-1.875 0-3.4-1.525-3.4-3.4s1.525-3.4 3.4-3.4 3.4 1.525 3.4 3.4-1.525 3.4-3.4 3.4zM5 8.86c-1.185 0-2.15.964-2.15 2.15s.965 2.15 2.15 2.15 2.15-.964 2.15-2.15-.965-2.15-2.15-2.15zm0 2.791a.65.65 0 1 1 0-1.3.65.65 0 0 1 0 1.3zm10-2.791c-1.185 0-2.15.964-2.15 2.15s.965 2.15 2.15 2.15 2.15-.964 2.15-2.15-.965-2.15-2.15-2.15zm0 2.791a.65.65 0 1 1 0-1.3.65.65 0 0 1 0 1.3z" />
        </svg>
    );
};

const PDFIcon = () => {
    return (
        <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V9C20 7.34315 18.6569 6 17 6H4ZM4 6V5" stroke="white" strokeWidth="1.5" />
            <path d="M18 6.00002V6.75002H18.75V6.00002H18ZM15.7172 2.32614L15.6111 1.58368L15.7172 2.32614ZM4.91959 3.86865L4.81353 3.12619H4.81353L4.91959 3.86865ZM5.07107 6.75002H18V5.25002H5.07107V6.75002ZM18.75 6.00002V4.30604H17.25V6.00002H18.75ZM15.6111 1.58368L4.81353 3.12619L5.02566 4.61111L15.8232 3.0686L15.6111 1.58368ZM4.81353 3.12619C3.91638 3.25435 3.25 4.0227 3.25 4.92895H4.75C4.75 4.76917 4.86749 4.63371 5.02566 4.61111L4.81353 3.12619ZM18.75 4.30604C18.75 2.63253 17.2678 1.34701 15.6111 1.58368L15.8232 3.0686C16.5763 2.96103 17.25 3.54535 17.25 4.30604H18.75ZM5.07107 5.25002C4.89375 5.25002 4.75 5.10627 4.75 4.92895H3.25C3.25 5.9347 4.06532 6.75002 5.07107 6.75002V5.25002Z" fill="white" />
            <path d="M8 12H16" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M8 15.5H13.5" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
};

const InstagramIcon = () => {
    return (
        <svg
            className="w-8 h-8 text-white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                fill="#ffffff"
            />
            <path
                d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                fill="#ffffff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                fill="#ffffff"
            />
        </svg>
    );
};

const MapIcon = () => {
    return (
        <svg
            className="w-8 h-8"
            viewBox="0 0 192 192"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <path
                stroke="#ffffff"
                strokeWidth="12"
                d="M96 22a51.88 51.88 0 0 0-36.77 15.303A52.368 52.368 0 0 0 44 74.246c0 16.596 4.296 28.669 20.811 48.898a163.733 163.733 0 0 1 20.053 28.38C90.852 163.721 90.146 172 96 172c5.854 0 5.148-8.279 11.136-20.476a163.723 163.723 0 0 1 20.053-28.38C143.704 102.915 148 90.841 148 74.246a52.37 52.37 0 0 0-15.23-36.943A51.88 51.88 0 0 0 96 22Z"
            />
            <circle cx="96" cy="74" r="20" stroke="#ffffff" strokeWidth="12" />
        </svg>
    );
};
const WhatsAppSVG = () => (
    <svg
        fill="#ffffff"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="w-8 h-8"
        viewBox="0 0 30.667 30.667"
        xmlSpace="preserve"
    >
        <g>
            <path d="M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017
      c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382
      c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076
      c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427
      c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437
      c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536
      c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609
      c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611
      c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787
      c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739
      C23.307,19.268,23.307,18.533,23.214,18.38z"
            />
        </g>
    </svg>
);

const LinkIcon = () => (
    <svg className="w-7 h-7" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7281 3.88396C17.1624 2.44407 19.2604 2.41383 20.4219 3.57981C21.5856 4.74798 21.5542 6.85922 20.1189 8.30009L17.6951 10.7333C17.4028 11.0268 17.4037 11.5017 17.6971 11.794C17.9906 12.0863 18.4655 12.0854 18.7578 11.7919L21.1816 9.35869C23.0929 7.43998 23.3329 4.37665 21.4846 2.5212C19.6342 0.663551 16.5776 0.905664 14.6653 2.82536L9.81768 7.69182C7.90639 9.61053 7.66643 12.6739 9.5147 14.5293C9.80702 14.8228 10.2819 14.8237 10.5754 14.5314C10.8688 14.2391 10.8697 13.7642 10.5774 13.4707C9.41376 12.3026 9.4451 10.1913 10.8804 8.75042L15.7281 3.88396Z" fill="#ffffff" />
        <path opacity="0.5" d="M14.4846 9.4707C14.1923 9.17724 13.7174 9.17632 13.4239 9.46864C13.1305 9.76097 13.1296 10.2358 13.4219 10.5293C14.5856 11.6975 14.5542 13.8087 13.1189 15.2496L8.27129 20.1161C6.83696 21.556 4.73889 21.5862 3.57742 20.4202C2.41376 19.2521 2.4451 17.1408 3.8804 15.6999L6.30424 13.2666C6.59657 12.9732 6.59565 12.4983 6.30219 12.206C6.00873 11.9137 5.53386 11.9146 5.24153 12.208L2.81769 14.6413C0.906387 16.56 0.666428 19.6234 2.5147 21.4788C4.36518 23.3365 7.42173 23.0944 9.334 21.1747L14.1816 16.3082C16.0929 14.3895 16.3329 11.3262 14.4846 9.4707Z" fill="#eeeeee" />
    </svg>
);

const CursorIcon5 = () => {
    return (
        <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.859 16.4999L12.3975 14.0385L11.6459 14.7902C10.8764 15.5597 10.4916 15.9444 10.0781 15.8536C9.66452 15.7628 9.47641 15.2522 9.10019 14.231L7.84544 10.8253C7.09492 8.78816 6.71966 7.7696 7.24463 7.24463C7.7696 6.71966 8.78816 7.09492 10.8253 7.84544L14.231 9.10019C15.2522 9.47641 15.7628 9.66451 15.8536 10.0781C15.9444 10.4916 15.5597 10.8764 14.7902 11.6459L14.0385 12.3975L16.4999 14.859C16.7548 15.1138 16.8822 15.2413 16.9411 15.3834C17.0196 15.573 17.0196 15.7859 16.9411 15.9755C16.8822 16.1176 16.7548 16.2451 16.4999 16.4999C16.2451 16.7548 16.1176 16.8822 15.9755 16.9411C15.7859 17.0196 15.573 17.0196 15.3834 16.9411C15.2413 16.8822 15.1138 16.7548 14.859 16.4999Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.5" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#aaaaaa" strokeWidth="1.5" />
        </svg>
    );
};

const CursorIcon2 = () => {
    return (
        <svg className="w-7- h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 3.5V2M5.06066 5.06066L4 4M5.06066 13L4 14.0607M13 5.06066L14.0607 4M3.5 9H2M15.8645 16.1896L13.3727 20.817C13.0881 21.3457 12.9457 21.61 12.7745 21.6769C12.6259 21.7349 12.4585 21.7185 12.324 21.6328C12.1689 21.534 12.0806 21.2471 11.9038 20.6733L8.44519 9.44525C8.3008 8.97651 8.2286 8.74213 8.28669 8.58383C8.33729 8.44595 8.44595 8.33729 8.58383 8.2867C8.74213 8.22861 8.9765 8.3008 9.44525 8.44519L20.6732 11.9038C21.247 12.0806 21.5339 12.169 21.6327 12.324C21.7185 12.4586 21.7348 12.6259 21.6768 12.7745C21.61 12.9458 21.3456 13.0881 20.817 13.3728L16.1896 15.8645C16.111 15.9068 16.0717 15.9279 16.0374 15.9551C16.0068 15.9792 15.9792 16.0068 15.9551 16.0374C15.9279 16.0717 15.9068 16.111 15.8645 16.1896Z" stroke="#888888" strokeWidth="2" strokeLinecap="" strokeLinejoin="round" />
        </svg>
    );
}

const CursorIcon = () => {
    return (
        <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 3.5V2M5.06066 5.06066L4 4M5.06066 13L4 14.0607M13 5.06066L14.0607 4M3.5 9H2M8.5 8.5L12.6111 21.2778L15.5 18.3889L19.1111 22L22 19.1111L18.3889 15.5L21.2778 12.6111L8.5 8.5Z" stroke="#444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

const AssetsIcon = ({ color, className }) => {
  return (
    <svg viewBox="0 0 1024 1024" className={`h-7 w-7 ${className}`} xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <path d="M91.89 238.457c-29.899 0-54.133 24.239-54.133 54.134 0 29.899 24.234 54.137 54.133 54.137s54.138-24.238 54.138-54.137c0-29.896-24.239-54.134-54.138-54.134z" fill={color ? color : "#E5594F"}></path>
      <path d="M91.89 462.463c-29.899 0-54.133 24.239-54.133 54.139 0 29.895 24.234 54.133 54.133 54.133s54.138-24.238 54.138-54.133c0-29.9-24.239-54.139-54.138-54.139z" fill={color ? color : "#C45FA0"}></path>
      <path d="M91.89 686.475c-29.899 0-54.133 24.237-54.133 54.133 0 29.899 24.234 54.138 54.133 54.138s54.138-24.238 54.138-54.138c0-29.896-24.239-54.133-54.138-54.133z" fill={color ? color : "#F39A2B"}></path>
      <path d="M941.26 234.723H328.964c-28.867 0-52.263 23.4-52.263 52.268v3.734c0 28.868 23.396 52.269 52.263 52.269H941.26c28.869 0 52.269-23.401 52.269-52.269v-3.734c-0.001-28.868-23.4-52.268-52.269-52.268z" fill={color ? color : "#F0D043"}></path>
      <path d="M941.26 682.74H328.964c-28.867 0-52.263 23.399-52.263 52.268v3.734c0 28.863 23.396 52.269 52.263 52.269H941.26c28.869 0 52.269-23.405 52.269-52.269v-3.734c-0.001-28.868-23.4-52.268-52.269-52.268z" fill={color ? color : "cyan"}></path>
      <path d="M709.781 458.729H328.964c-28.867 0-52.263 23.4-52.263 52.269v3.734c0 28.873 23.396 52.269 52.263 52.269h380.817c28.866 0 52.271-23.396 52.271-52.269v-3.734c0.001-28.869-23.405-52.269-52.271-52.269z" fill={color ? color : "#E5594F"}></path>
    </svg>
  );
};

const ChatsIcon = () => {
    return (
      <svg viewBox="0 0 72 72" className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="#000000">
        <g id="color">
          <path fill="#ffd22e" stroke="none" d="M15.042,14.9756c-2.2091,0-4,1.7909-4,4v11.7841l-0.0019,4.5314c-0.0002,0.2808,0.328,0.4336,0.5428,0.2527 l5.0257-4.389c0.1804-0.1518,0.4086-0.2351,0.6443-0.2351h30.9557c2.2091,0,4-1.7909,4-4v-7.9441c0-2.2091-1.7909-4-4-4H15.042z"></path>
          <path fill="#6cb6e4" stroke="none" d="M60.5096,60.5444c0.2147,0.1808,0.5427,0.0281,0.5426-0.2526l-0.0018-4.2707h0.0007V44.077 c0-2.2091-1.7909-4-4-4H24.103c-2.2091,0-4,1.7909-4,4v7.9441c0,2.2091,1.7909,4,4,4h30.8579c0.2357,0,0.4639,0.0833,0.6441,0.2351 L60.5096,60.5444z"></path>
        </g>
        <g id="line">
          <path fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="2" d="M15.042,14.9756c-2.2091,0-4,1.7909-4,4v11.7841 l-0.0019,4.5314c-0.0002,0.2808,0.328,0.4336,0.5428,0.2527l5.0257-4.389c0.1804-0.1518,0.4086-0.2351,0.6443-0.2351h30.9557 c2.2091,0,4-1.7909,4-4v-7.9441c0-2.2091-1.7909-4-4-4H15.042z"></path>
          <path fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="2" d="M60.5096,60.5444 c0.2147,0.1808,0.5427,0.0281,0.5426-0.2526l-0.0018-4.2707h0.0007V44.077c0-2.2091-1.7909-4-4-4H24.103c-2.2091,0-4,1.7909-4,4 v7.9441c0,2.2091,1.7909,4,4,4h30.8579c0.2357,0,0.4639,0.0833,0.6441,0.2351L60.5096,60.5444z"></path>
          <circle cx="31.9965" cy="48.0593" r="2" fill="#ffffff" stroke="none"></circle>
          <circle cx="40.0081" cy="48.0593" r="2" fill="#ffffff" stroke="none"></circle>
          <circle cx="48.0198" cy="48.0593" r="2" fill="#ffffff" stroke="none"></circle>
        </g>
      </svg>
    );
  };

let rotation = {
    "up": " -rotate-90",
    "down": "rotate-90",
    "left": "rotate-180",
    "right": "",
}

const ArrowIcon = ({ color, direction="right" }) => {

  return (
    <svg viewBox="0 0 24 24" fill="none" className={`h-8 w-8 ${rotation[direction]}`} xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const MenuIcon2 = ({ color="#ffffff"}) => {
    return (
      <svg viewBox="0 0 24 24" className="h-8 w-8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M7 12H17M9 18H15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  };

const SendIcon = ({ ready, onClick }) => {
    //'#38a169'
    //'#ffbf00'
    let color = ready ? '#ffffff' : '#25252500'
    return (
      <svg
        fill="#000000"
        viewBox="0 0 24 24"
        id="send-alt"
        data-name="Flat Color"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0.5 right-0.5 p-1 rounded-lg hover:bg-slate-900 h-8 w-8 icon flat-color"
        onClick={() => onClick()}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            id="primary"
            d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.13-5.52-.38-1-1-.4L3.31,11.63a2,2,0,0,1,0-3.83h0L19.27,2.12a2,2,0,0,1,2.61,2.61Z"
            stroke={color}
            strokeWidth={1.5}
            style={{ fill: 'none' }}
          ></path>
          <path
            id="secondary"
            d="M14.28,11.14l-4,4-.38-1-1-.4,4-4a1,1,0,1,1,1.42,1.42Z"
            stroke={color}
            style={{ fill: "none" }}
            ></path>
        </g>
      </svg>
    );
};

const MenuIcon3 = ({ color="#ffffff", className, onClick }) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={`h-8 w-8 ${className}`} xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path opacity="0.4" d="M17.5401 8.81014C19.1748 8.81014 20.5001 7.4849 20.5001 5.85014C20.5001 4.21537 19.1748 2.89014 17.5401 2.89014C15.9053 2.89014 14.5801 4.21537 14.5801 5.85014C14.5801 7.4849 15.9053 8.81014 17.5401 8.81014Z" fill={color}></path>
          <path d="M6.46 8.81014C8.09476 8.81014 9.42 7.4849 9.42 5.85014C9.42 4.21537 8.09476 2.89014 6.46 2.89014C4.82524 2.89014 3.5 4.21537 3.5 5.85014C3.5 7.4849 4.82524 8.81014 6.46 8.81014Z" fill={color}></path>
          <path d="M17.5401 21.1099C19.1748 21.1099 20.5001 19.7847 20.5001 18.1499C20.5001 16.5152 19.1748 15.1899 17.5401 15.1899C15.9053 15.1899 14.5801 16.5152 14.5801 18.1499C14.5801 19.7847 15.9053 21.1099 17.5401 21.1099Z" fill={color}></path>
          <path opacity="0.4" d="M6.46 21.1099C8.09476 21.1099 9.42 19.7847 9.42 18.1499C9.42 16.5152 8.09476 15.1899 6.46 15.1899C4.82524 15.1899 3.5 16.5152 3.5 18.1499C3.5 19.7847 4.82524 21.1099 6.46 21.1099Z" fill={color}></path>
        </g>
      </svg>
    );
  }

  const MenuIcon = ({ color="#ffffff", className, onClick }) => {
    return (
      <svg viewBox="0 0 64 64" fill="none" className={`h-8 w-8 ${className}`} xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path d="M58.38 21.701C58.3845 22.4886 58.1675 23.2616 57.7538 23.9318C57.34 24.6019 56.7461 25.1423 56.0399 25.491L34.27 36.371C33.6842 36.6676 33.0366 36.8218 32.38 36.821C31.7233 36.8218 31.0758 36.6676 30.49 36.371L8.71994 25.491C8.01597 25.1422 7.42371 24.6032 7.01016 23.9352C6.59662 23.2671 6.37835 22.4967 6.37998 21.711C6.37694 20.9238 6.59455 20.1514 7.00815 19.4815C7.42174 18.8116 8.01474 18.271 8.71994 17.921L30.49 7.04104C31.0765 6.74751 31.7241 6.59674 32.38 6.60104C33.0358 6.59674 33.6835 6.74751 34.27 7.04104L56.0399 17.921C56.7439 18.2699 57.3362 18.8089 57.7497 19.4769C58.1633 20.1449 58.3816 20.9154 58.38 21.701Z" fill="#fff" />
        <path d="M56.04 27.9509C56.7452 28.3009 57.3382 28.8415 57.7518 29.5114C58.1654 30.1813 58.383 30.9537 58.38 31.741C58.3816 32.5266 58.1633 33.2971 57.7498 33.9651C57.3362 34.6331 56.744 35.1721 56.04 35.521L34.27 46.4109C33.6818 46.6997 33.0353 46.8502 32.38 46.851C31.7247 46.8502 31.0782 46.6997 30.49 46.4109L8.71998 35.521C8.01722 35.1715 7.42642 34.6322 7.0146 33.964C6.60277 33.2959 6.38643 32.5258 6.39002 31.741C6.38547 30.9534 6.60242 30.1804 7.01618 29.5102C7.42995 28.84 8.02383 28.2997 8.72999 27.9509L8.95002 27.8409L13.42 30.0809L29.6 38.1609C30.4632 38.5928 31.4148 38.8187 32.38 38.821C33.3484 38.8187 34.3032 38.5928 35.17 38.1609L51.34 30.0809L55.82 27.8409L56.04 27.9509Z" fill={'#888'} />
        <path d="M56.04 37.991C56.7439 38.3399 57.3362 38.8788 57.7497 39.5468C58.1633 40.2149 58.3816 40.9853 58.38 41.771C58.3816 42.5567 58.1633 43.3271 57.7497 43.9951C57.3362 44.6631 56.7439 45.2021 56.04 45.551L34.27 56.441C33.6842 56.7376 33.0366 56.8918 32.38 56.891C31.7234 56.8918 31.0758 56.7376 30.49 56.441L8.71996 45.551C8.0172 45.2015 7.4264 44.6622 7.01458 43.9941C6.60275 43.3259 6.38641 42.5558 6.39 41.771C6.3843 40.9857 6.59977 40.2147 7.01183 39.5462C7.42389 38.8777 8.01586 38.3387 8.71996 37.991L8.95 37.871L13.43 40.111L29.61 48.201C30.4708 48.6285 31.4189 48.851 32.38 48.851C33.3442 48.8499 34.2952 48.6275 35.16 48.201L51.35 40.111L55.81 37.871L56.04 37.991Z" fill="#555" />
      </svg>
    );
  }

function GaleryIcon({ className, color="#000000" }) {
    return (
      <svg viewBox="0 0 48 48" fill={color} className={`h-8 w-8 ${className}`} >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <defs>
            <style>{`.b{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;}`}</style>
          </defs>
          <path className="b" strokeWidth="3" d="M29.4995,12.3739c.7719-.0965,1.5437,.4824,1.5437,1.2543h0l2.5085,23.8312c.0965,.7719-.4824,1.5437-1.2543,1.5437l-23.7347,2.5085c-.7719,.0965-1.5437-.4824-1.5437-1.2543h0l-2.5085-23.7347c-.0965-.7719,.4824-1.5437,1.2543-1.5437l23.7347-2.605Z"></path>
          <path className="b" strokeWidth="3" d="M12.9045,18.9347c-1.7367,.193-3.0874,1.7367-2.8945,3.5699,.193,1.7367,1.7367,3.0874,3.5699,2.8945,1.7367-.193,3.0874-1.7367,2.8945-3.5699s-1.8332-3.0874-3.5699-2.8945h0Zm8.7799,5.596l-4.6312,5.6925c-.193,.193-.4824,.2894-.6754,.0965h0l-1.0613-.8683c-.193-.193-.5789-.0965-.6754,.0965l-5.0171,6.1749c-.193,.193-.193,.5789,.0965,.6754-.0965,.0965,.0965,.0965,.193,.0965l19.9719-2.1226c.2894,0,.4824-.2894,.4824-.5789,0-.0965-.0965-.193-.0965-.2894l-7.8151-9.0694c-.2894-.0965-.5789-.0965-.7719,.0965h0Z"></path>
          <path className="b" strokeWidth="3" d="M16.2814,13.8211l.6754-6.0784c.0965-.7719,.7719-1.3508,1.5437-1.2543l23.7347,2.5085c.7719,.0965,1.3508,.7719,1.2543,1.5437h0l-2.5085,23.7347c0,.6754-.7719,1.2543-1.5437,1.2543l-6.1749-.6754"></path>
          <path className="b" strokeWidth="3" d="M32.7799,29.9337l5.3065,.5789c.2894,0,.4824-.193,.5789-.4824,0-.0965,0-.193-.0965-.2894l-5.789-10.5166c-.0965-.193-.4824-.2894-.6754-.193h0l-.3859,.3859"></path>
        </g>
      </svg>
    );
  }

  function ImageIcon({color="#ffffff"}) {
    return (
      <svg viewBox="0 0 24 24" fill="none" className="h-8 w-8" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M14.2639 15.9375L12.5958 14.2834C11.7909 13.4851 11.3884 13.086 10.9266 12.9401C10.5204 12.8118 10.0838 12.8165 9.68048 12.9536C9.22188 13.1095 8.82814 13.5172 8.04068 14.3326L4.04409 18.2801M14.2639 15.9375L14.6053 15.599C15.4112 14.7998 15.8141 14.4002 16.2765 14.2543C16.6831 14.126 17.12 14.1311 17.5236 14.2687C17.9824 14.4251 18.3761 14.8339 19.1634 15.6514L20 16.4934M14.2639 15.9375L18.275 19.9565M18.275 19.9565C17.9176 20 17.4543 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.71569 19.5903 4.40973 19.2843 4.21799 18.908C4.12796 18.7313 4.07512 18.5321 4.04409 18.2801M18.275 19.9565C18.5293 19.9256 18.7301 19.8727 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V16.4934M4.04409 18.2801C4 17.9221 4 17.4575 4 16.8V7.2C4 6.0799 4 5.51984 4.21799 5.09202C4.40973 4.71569 4.71569 4.40973 5.09202 4.21799C5.51984 4 6.07989 4 7.2 4H16.8C17.9201 4 18.4802 4 18.908 4.21799C19.2843 4.40973 19.5903 4.71569 19.782 5.09202C20 5.51984 20 6.0799 20 7.2V16.4934M17 8.99989C17 10.1045 16.1046 10.9999 15 10.9999C13.8954 10.9999 13 10.1045 13 8.99989C13 7.89532 13.8954 6.99989 15 6.99989C16.1046 6.99989 17 7.89532 17 8.99989Z" stroke={color} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
      </svg>
    );
  }

  function CameraIcon2({ color }) {
    return (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" xmlnsXlink="http://www.w3.org/1999/xlink" fill={color}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <defs>
            <path id="camera-a" d="M5.76389349,3 C6.31321405,3.61374936 7.11150019,4 8,4 C8.88849981,4 9.68678595,3.61374936 10.2361065,3 L16,3 L16,7 L0,7 L0,3 L5.76389349,3 Z M8,2 C7.44771525,2 7,1.55228475 7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 C9,1.55228475 8.55228475,2 8,2 Z"></path>
            <path id="camera-c" d="M2,4 L2,15 L18,15 L18,4 L14,4 L12,2 L8,2 L6,4 L2,4 Z M5.17157288,2 L6.58578644,0.585786438 C6.96085919,0.210713681 7.46956702,0 8,0 L12,0 C12.530433,0 13.0391408,0.210713681 13.4142136,0.585786438 L14.8284271,2 L18,2 C19.1045695,2 20,2.8954305 20,4 L20,15 C20,16.1045695 19.1045695,17 18,17 L2,17 C0.8954305,17 0,16.1045695 0,15 L0,4 C0,2.8954305 0.8954305,2 2,2 L5.17157288,2 Z M10,13 C12.209139,13 14,11.209139 14,9 C14,6.790861 12.209139,5 10,5 C7.790861,5 6,6.790861 6,9 C6,11.209139 7.790861,13 10,13 Z M10,11 C8.8954305,11 8,10.1045695 8,9 C8,7.8954305 8.8954305,7 10,7 C11.1045695,7 12,7.8954305 12,9 C12,10.1045695 11.1045695,11 10,11 Z"></path>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(2 3)">
            <g transform="translate(2 8)">
              <mask id="camera-b" fill="#ffffff">
                <use xlinkHref="#camera-a"></use>
              </mask>
              <use fill="#D8D8D8" xlinkHref="#camera-a"></use>
              <g fill="#FFA0A0" mask="url(#camera-b)">
                <rect width="24" height="24" transform="translate(-4 -11)"></rect>
              </g>
            </g>
            <mask id="camera-d" fill="#ffffff">
              <use xlinkHref="#camera-c"></use>
            </mask>
            <use fill="#000000" fillRule="nonzero" xlinkHref="#camera-c"></use>
            <g fill="#7600FF" mask="url(#camera-d)">
              <rect width="24" height="24" transform="translate(-2 -3)"></rect>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  const CameraIcon = ({ color="#ffffff"}) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 16.8V9.2C3 8.0799 3 7.51984 3.21799 7.09202C3.40973 6.71569 3.71569 6.40973 4.09202 6.21799C4.51984 6 5.0799 6 6.2 6H7.25464C7.37758 6 7.43905 6 7.49576 5.9935C7.79166 5.95961 8.05705 5.79559 8.21969 5.54609C8.25086 5.49827 8.27836 5.44328 8.33333 5.33333C8.44329 5.11342 8.49827 5.00346 8.56062 4.90782C8.8859 4.40882 9.41668 4.08078 10.0085 4.01299C10.1219 4 10.2448 4 10.4907 4H13.5093C13.7552 4 13.8781 4 13.9915 4.01299C14.5833 4.08078 15.1141 4.40882 15.4394 4.90782C15.5017 5.00345 15.5567 5.11345 15.6667 5.33333C15.7216 5.44329 15.7491 5.49827 15.7803 5.54609C15.943 5.79559 16.2083 5.95961 16.5042 5.9935C16.561 6 16.6224 6 16.7454 6H17.8C18.9201 6 19.4802 6 19.908 6.21799C20.2843 6.40973 20.5903 6.71569 20.782 7.09202C21 7.51984 21 8.0799 21 9.2V16.8C21 17.9201 21 18.4802 20.782 18.908C20.5903 19.2843 20.2843 19.5903 19.908 19.782C19.4802 20 18.9201 20 17.8 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

  function FolderIcon(props) {
    return (
        <svg className={props.className} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill={props.fill}>
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M910.5 829.36666667H175.2c-27.4 0-49.6-22.3-49.6-49.6V284.26666667c0-27.4 22.3-49.6 49.6-49.6h735.3c27.4 0 49.6 22.3 49.6 49.6v495.5c0 27.4-22.2 49.6-49.6 49.6z" fill="#000000"></path>
            <path d="M870.7 178.96666667H118.6c-15.3 0-27.7 12.4-27.7 27.7v512.3c0 15.3 12.4 27.7 27.7 27.7h752.1c15.3 0 27.7-12.4 27.7-27.7V206.66666667c0-15.2-12.4-27.7-27.7-27.7z" fill="#fff6d6"></path>
            <path d="M870.7 151.96666667H118.6c-30.2 0-54.7 24.6-54.7 54.7v512.3c0 30.2 24.6 54.7 54.7 54.7h752.1c30.2 0 54.7-24.6 54.7-54.7V206.66666667c0.1-30.2-24.5-54.7-54.7-54.7z m27.7 567c0 15.3-12.4 27.7-27.7 27.7H118.6c-15.3 0-27.7-12.4-27.7-27.7V206.66666667c0-15.3 12.4-27.7 27.7-27.7h752.1c15.3 0 27.7 12.4 27.7 27.7v512.3z" fill="#fff6d6"></path>
            <path d="M834 524.46666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V234.96666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3v263.2c0 14.5-11.8 26.3-26.3 26.3z" fill="#3D90BC"></path>
            <path d="M90.3 452.26666667H898v210.5H90.3z" fill="#B7B4AB"></path>
            <path d="M834 587.66666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V298.16666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3v263.2c0 14.5-11.8 26.3-26.3 26.3z" fill="#2A80AC"></path>
            <path d="M834 593.66666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V304.16666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3v263.2c0 14.5-11.8 26.3-26.3 26.3z" fill="#F4AA16"></path>
            <path d="M834 650.76666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V361.26666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3v263.2c0 14.5-11.8 26.3-26.3 26.3z" fill="#DF9513"></path>
            <path d="M834 656.76666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V367.36666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3v263.2c0 14.5-11.8 26.2-26.3 26.2z" fill="#52B240"></path>
            <path d="M834 713.96666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V424.46666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3V687.66666667c0 14.5-11.8 26.3-26.3 26.3z" fill="#409F37"></path>
            <path d="M834 719.96666667H157.3c-14.5 0-26.3-11.8-26.3-26.3V430.46666667c0-14.5 11.8-26.3 26.3-26.3H834c14.5 0 26.3 11.8 26.3 26.3V693.66666667c0 14.5-11.8 26.3-26.3 26.3z" fill="#ff0000"></path>
            <path d="M860.3 452.26666667H614.5c-18.6 0-33.2 15.3-33.2 33.9 0 1.5 0 3.1-0.1 4.6-2.1 44-37.6 80-81.5 82.7-50.6 3.1-92.6-37-92.6-86.9v-0.4c0.1-18.6-14.5-33.9-33.2-33.9h-243V684.66666667c0 14.5 11.8 26.3 26.3 26.3H834c14.5 0 26.3-11.8 26.3-26.3V452.26666667z" fill="#D95F52"></path>
            <path d="M616.8 464.26666667c-11.4 0-20.4 9.4-20.4 20.8v0.2c0 56.5-45.7 102.4-102.2 102.4S392 541.86666667 392 485.36666667v-0.2c0-11.4-9-20.8-20.4-20.8H90.3v250.5c0 17.8 14.5 32.3 32.3 32.3h743.1c17.8 0 32.3-14.5 32.3-32.3V464.26666667H616.8z" fill="#fff6d6"></path>
          </g>
        </svg>
      );
  }

  function ImageTabletIcon(props) {
    return (
      <svg className={props.className} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill={props.fill}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M910.4 843.8H174.6c-27.4 0-49.7-22.3-49.7-49.7V298.2c0-27.4 22.3-49.7 49.7-49.7h735.8c27.4 0 49.7 22.3 49.7 49.7v495.9c0 27.4-22.3 49.7-49.7 49.7z" fill="#000000"></path>
          <path d="M272.1 193.8H118.7c-22.8 0-41.2 18.5-41.2 41.2v512.7c0 22.8 18.5 41.2 41.2 41.2h752.7c22.8 0 41.2-18.5 41.2-41.2V235c0-22.8-18.5-41.2-41.2-41.2H272.1z" fill="#FFFFFF"></path>
          <path d="M871.4 802.5H118.7c-30.2 0-54.8-24.6-54.8-54.8V235c0-30.2 24.6-54.8 54.8-54.8h752.7c30.2 0 54.8 24.6 54.8 54.8v512.7c0 30.3-24.6 54.8-54.8 54.8zM118.7 207.3c-15.3 0-27.7 12.4-27.7 27.7v512.7c0 15.3 12.4 27.7 27.7 27.7h752.7c15.3 0 27.7-12.4 27.7-27.7V235c0-15.3-12.4-27.7-27.7-27.7H118.7z" fill="#000000"></path>
          <path d="M302.8 246.7H170.5c-19.6 0-35.6 13.6-35.6 30.3v376.5c0 16.7 15.9 30.3 35.6 30.3h649.1c19.6 0 35.6-13.6 35.6-30.3V277c0-16.7-15.9-30.3-35.6-30.3H302.8z" fill="#95D4EB"></path>
          <path d="M430.8 683.8L230.3 483.3 135 578.6v105.2z" fill="#75BFAB"></path>
          <path d="M374.4 394.3m-98.8 0a98.8 98.8 0 1 0 197.6 0 98.8 98.8 0 1 0-197.6 0Z" fill="#F9F5B1"></path>
          <path d="M855.1 630L551.5 326.4 194.3 683.7h660.8z" fill="#57B79C"></path>
          <path d="M855.1 521.8l-83-83-245 245h328z" fill="#75BFAB"></path>
          <path d="M709.9 743.8h-33.1c-0.8 0-1.5-0.7-1.5-1.5v-33.1c0-0.8 0.7-1.5 1.5-1.5h33.1c0.8 0 1.5 0.7 1.5 1.5v33.1c0 0.9-0.7 1.5-1.5 1.5zM774.2 743.8h-33.1c-0.8 0-1.5-0.7-1.5-1.5v-33.1c0-0.8 0.7-1.5 1.5-1.5h33.1c0.8 0 1.5 0.7 1.5 1.5v33.1c0 0.9-0.6 1.5-1.5 1.5zM838.6 743.8h-33.1c-0.8 0-1.5-0.7-1.5-1.5v-33.1c0-0.8 0.7-1.5 1.5-1.5h33.1c0.8 0 1.5 0.7 1.5 1.5v33.1c0 0.9-0.7 1.5-1.5 1.5z" fill="#000000"></path>
        </g>
      </svg>
    );
  }

  const FullScreenIcon = ({ className }) => (
    <svg viewBox="0 0 48 48" fill="none" className={`p-0.5 h-7 w-7 bg-white/20 rounded-md ${className} `} xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" fillOpacity="0.01"></rect>
      <path d="M6 6L16 15.8995" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M6 41.8995L16 32" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M42.0001 41.8995L32.1006 32" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M41.8995 6L32 15.8995" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M33 6H42V15" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M42 33V42H33" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M15 42H6V33" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M6 15V6H15" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
  );
/*
    const SendIcon2 = () => {
        return (
            <svg
                className="w-12 h-12 absolute top-0 right-1"
                viewBox="-2.4 -2.4 28.80 28.80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.144" />
                <g id="SVGRepo_iconCarrier">
                <path
                    d="M7.6921 11.8974L9.10263 12.3675L9.10264 12.3675C10.0337 12.6779 10.4992 12.8331 10.8331 13.1669C11.1669 13.5008 11.3221 13.9663 11.6325 14.8974L11.6325 14.8974L12.1026 16.3079C12.887 18.6611 13.2792 19.8377 14 19.8377C14.7208 19.8377 15.113 18.6611 15.8974 16.3079L15.8974 16.3079L18.7351 7.79473C19.287 6.13894 19.563 5.31105 19.126 4.87403C18.689 4.43702 17.8611 4.71298 16.2053 5.26491L7.6921 8.10263C5.33889 8.88704 4.16228 9.27924 4.16228 10C4.16228 10.7208 5.33888 11.113 7.69209 11.8974L7.6921 11.8974Z"
                    fill="#ffffff55"
                    fillOpacity="1"
                />
                <path
                    d="M9.52566 13.8419L7.46399 13.1547C7.17123 13.0571 6.84982 13.1001 6.59306 13.2713L5.50471 13.9969C4.92684 14.3821 5.08067 15.2702 5.75444 15.4386L7.70896 15.9272C7.8881 15.972 8.02798 16.1119 8.07276 16.291L8.56139 18.2456C8.72983 18.9193 9.6179 19.0732 10.0031 18.4953L10.7287 17.4069C10.8999 17.1502 10.9429 16.8288 10.8453 16.536L10.1581 14.4743C10.0586 14.1757 9.82426 13.9414 9.52566 13.8419Z"
                    fill="#ffbf00"
                />
                </g>
            </svg>
        );
    };

    function SendIcon1() {

        return (
            <svg onClick={() => submit()} className={"absolute top-1.5 right-1 w-7 h-7 text-slate-500 hover:text-green-500"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
            </svg>
        )
    }
*/

export {
    GaleryIcon,
    ImageIcon,
    SendIcon,
    FacebookIcon,
    BookIcon,
    YouTubeIcon,
    TwitterIcon,
    PanoramicIcon,
    TripAdvisorIcon,
    PDFIcon,
    InstagramIcon,
    MapIcon,
    WhatsAppSVG,
    LinkIcon,
    CursorIcon,
    AssetsIcon,
    ChatsIcon,
    ArrowIcon,
    MenuIcon,
    CameraIcon,
    FolderIcon,
    ImageTabletIcon,
    FullScreenIcon
}