import languages from "../data/languages"
import { getObjectStorage, setObjectStorage } from "./store"

function getProfile() {

    return getObjectStorage("profile", {
        name:"",
        language:{
            code:"es-MX",
            name:'Español',
            //code:'es',
            //country:"MX",
        }
    })
}

function updateLanguageProfile( lang ) {

    let updated = false
    let obj = getProfile()
    let befCode = obj.language ? obj.language.code.split("-")[0] : null
    let rLang
    let [ code, country ] = lang.split("-")

    if(befCode !== code) {

        let name = languages[code].lang[0]

        if(!country) {
            country = getCountryLanguage(code)
        }

        obj.language = {
            code: lang,
            name
        }

        setObjectStorage("profile", obj)

        rLang = name
        country = country.toLocaleUpperCase()
        updated = true
    }

    return [ rLang, country, updated ]
}

function getCountryLanguage(lang) {

    const { countries } = languages[lang] || {}
    
    return  Object.keys(countries)[0]
}

function getName() {

    return localStorage.getItem("userName")
}

function getPreferences() {

    return localStorage.getItem("preferences")
}

export { getProfile, updateLanguageProfile }