import { useState } from 'react'
import ctx from './ctx'
import updater from './updater'

export default function useData(data, id=data, o="a", metadata) {

    var val = ctx.data

    const a = data.split("/")

    for(let p of a) {

        val = val[p]

        if(val === undefined)
            break;
    }

    const [state, setState] = useState(val)

    if(ctx.dispatchers[data])
        ctx.dispatchers[data][id] = setState
    else
        ctx.dispatchers[data] = {
            [id]: setState
        }

    return [state, updater(data, o, metadata)]
}