import { useEffect, useState } from "react";
import ctx from "../context/ctx";
import updater from "../context/updater";

export default function useSocket(props={}) {

    var [ socket, setSocket ] = useState({ready:false})

    useEffect(() => {

        startWebSocket(props)

    }, [])


    function startWebSocket(props) {

        const { name='default', path='', onOpen, actions={} } = props || {}

        //console.log("UNA VEZ")

        const { url } = ctx.sockets[name]
        const socket = new WebSocket(url)

        socket.actions = actions
        socket.onopen = (e) => {

            socket.ready()
            onOpen && onOpen(socket)
            //console.log("SOCKET [OPEN]", e, socket)
        }
        socket.onclose = (e) => {

            ctx.sockets.default.connected = false
            //setSocket(ctx.sockets.default)
            //console.log("SOCKET.Close", e, socket)
        }
        socket.onerror = (e) => {

            console.log("SOCKET.Error", e)
        }

        socket.onmessage = (e) => {

            const {action, success, data} = JSON.parse(e.data) || {}
            const event = ctx.sockets.default.actions[action]

            //console.log("--------------------", action, data)

            if(event) {
                event(socket, data)
            } else {
                updater(`${action}`)(data)
            }
        }

        socket.setAuthorization = (token, ) => {

            socket.authorization = token
            socket.property({authorized:true})
            props.onAuthorized && props.onAuthorized(socket)
        }

        socket.emit = (action, data={}) =>  {

            //console.log("EMIT:", socket)

            if(socket.connected) {
                const { authorization } = socket

                const pack = JSON.stringify({
                    action,
                    data,
                    authorization,
                    //timestamp:Date.now()
                })
    
                socket.send(pack)

            } else {

                console.log("SOCKET [CLOSE]")

                startWebSocket({ ...props, onAuthorized: (e) => {
                    console.log("RE-EMITION")
                    e.emit(action, data)
                }})

            }
        }

        socket.ready = (props={}) => {

            ctx.sockets[name] = socket
            socket.connected = true
            let actions = { ...props.actions, ...socket.actions }

            ctx.sockets.default = { socket, ready:true, connected:true, ...props, ...socket, ...actions }

            setSocket( ctx.sockets.default )    
        }

        socket.property = (props={}) => {

            ctx.sockets[name] = socket
            setSocket({...socket, ...props})    
        }
    }

    return socket
}

/*
socket.reset = (e) => {

    setTimestamp(Date.now()); return true
}
*/

            //if(useToken)
            //    pack.token = getToken().token

            //console.log("EMIT.pack", pack)
