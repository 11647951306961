import updater from "../vio/context/updater"
import { getProfile, updateLanguageProfile } from "./profile"
import languages from "../data/languages"
import { updateMessage } from "./messages"
import { getStorage, setObjectStorage, setStorage } from "./store"

var init = 0
var max = 0
var chunks = {}
var message = ""

function messages(skt, data) {

    var { id, index, chunk } = data
    chunks[index] = chunk

    if (index > max)
        max = index

    var fragment = ""

    for (var i = init; i <= max; i++) {

        let chunk = chunks[i]

        if (chunk === undefined) {
            break;
        } else {
            fragment += chunk
            init++
        }
    }

    if (fragment) {
        
        message += fragment
        updateMessage({ id, state:'writing', message })
    }
}

function settings(skt, data) {

    var country = ""
    const { parsed, id, lang, plugins, usage, latency, stop, shortcut, userName, emoticon } = data

    //if(stop === undefined)
    //    console.log(">> START", { lang, latency, usage})

    //console.log(">> SETTINGS", data)

    if(userName && (getStorage("userName") === undefined || getStorage("userName") === null)) {
        setStorage("userName", userName)
        updater(`userName`)(userName, "r")
    }

    //const { lang } = data || {}
    const { total } = usage || {}

    var props = {}

    if(plugins) {
        props.plugins = plugins
    }

    if(total) {

        //updater(`usageAmount`)(Number(total), "s")
        //updater(`counterMessages`)(1, "s")

        let cost = Number(total).toFixed(2)
        updateMessage( { id, cost })
    }

    if(lang) {

        console.log(">> LANG", lang)

        let [ langg, country, updated ] = updateLanguageProfile(lang)

        console.log(">> LANG", langg, country, updated)
        if(updated)
            props.config = { lang: langg, country }
    }

    if(stop) {
        props = { ...props, message: data.message, state: 'close'}
        //console.log(">> END::", total, "::", message, usage)

        updateMessage( { id, message })

        searchTags(message)
        init = 0
        max = 0
        chunks = {}
        message = ""
    }

    if(shortcut) {

        setObjectStorage("shortcuts", shortcut)
        updater(`shortcuts`)(shortcut, "r")
        //props.shortcut = shortcut
    }

    if(emoticon) {
        props.emoticon = emoticon
        updateMessage( { id: id - 1, emoticon })
    }

    if(Object.keys(props).length > 0) {

        updateMessage({ id, ...props })
    }
}

function searchTags(str) {

    const nm = getTagValue(str, "nm")

    if(nm) {
        localStorage.setItem("userName", nm)
    }

    const pf = getTagValue(str, "pf")

    if(pf) {

        const set = new Set(pf.split("|"));

        let pref = localStorage.getItem("preferences", pf)

        if(pref === null) {
            
            localStorage.setItem("preferences", Array.from(set).join(", "))

        } else {

            let newSet = new Set([...pref.split(", "), ...set])
            console.log("NEW SET", newSet)
            localStorage.setItem("preferences", Array.from(newSet).join(", "))
        }
    }

    const ln = getTagValue(str, "lg")

    if(ln) {
        localStorage.setItem("lang", ln)
    }

    //console.log("----------STORAGE", getName(), getPreferences())
}

function getTagValue(str, tag) {

    const left = `[${tag}]`
    const right = `[/${tag}]`

    const start = str.indexOf(left)
    const end = str.indexOf(right)

    if(start > -1 && end > -1 && end > start) {

        return str.substring(start + left.length, end)

    } else {

        return undefined
    }
}

export { messages, settings }