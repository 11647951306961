import React, { useState } from 'react';
import CloseIcon from '../commons/CloseIcon';
import Button from './Button';

export default function BookingForm({ src, icon, className, switchOpen }) {
    const [checkin, setCheckin] = useState('');
    const [checkout, setCheckout] = useState('');
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [numRooms, setNumRooms] = useState(1);
    const [childAge, setChildAge] = useState([]);

    function setChildAgeHandler(e) {
        const index = parseInt(e.target.id.replace('childAge', ''));
        const newArray = childAge;
        newArray[index - 1] = parseInt(e.target.value);
        setChildAge(newArray);
    }

    //console.log("url", src)
    const handleSubmit = (e) => {
        //e.preventDefault();

        //let url = `https://book.omnibees.com/hotelresults?c=7922&q=13791&hotel_folder=&NRooms=1&CheckIn=29012024&CheckOut=02022024&ad=2&ch=2&ag=0%3B5&child_age_input=0%3B5&group_code=&Code=&loyalty_code=&lang=es-ES&currencyId=66`
        //checkin, checkout, adults, children, numRooms
        // https://book.omnibees.com/hotelresults?c=7922&q=13791&hotel_folder=&NRooms=1&CheckIn=29012024&CheckOut=02022024&ad=2&ch=6&ag=7%3B1%3B2%3B3%3B4%3B5&child_age_input=7%3B1%3B2%3B3%3B4%3B5&group_code=&Code=&loyalty_code=&lang=es-ES&currencyId=66
        //let url = `https://book.omnibees.com/hotelresults?c=7922&q=13791&hotel_folder=&NRooms=${numRooms}&CheckIn=${formatDate(checkin)}&CheckOut=${formatDate(checkout)}&ad=${adults}&ch=${children}&ag=0%3B5&child_age_input=0%3B5&group_code=&Code=&loyalty_code=&lang=es-ES&currencyId=66`;
        //let url = `https://direct-book.com/properties/QuintaBellaHuatulcoDirect?locale=es&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=MXN&checkInDate=2024-08-06&checkOutDate=2024-08-07&trackPage=no`
        let url = `https://direct-book.com/properties/QuintaBellaHuatulcoDirect?locale=en&items[0][adults]=${adults}&items[0][children]=${children}&items[0][infants]=0&currency=USD&checkInDate=${formatDate(checkin)}&checkOutDate=${formatDate(checkout)}&trackPage=no`
        console.log("url", url)
        window.open(url, '_blank');
            // Aquí puedes agregar la lógica para manejar los datos del formulario
            // Por ejemplo, enviar los datos a un servidor o realizar alguna acción local.
    };

    function formatDate(inputDate, separator = '-') {
        const dateParts = inputDate.split('-');
        const formattedDate = `${dateParts[2]}${separator}${dateParts[1]}${separator}${dateParts[0]}`;
        return formattedDate;
      }

      const generateAgeOptions = () => {
        const options = [];
        for (let i = 0; i <= 17; i++) {
            options.push(<option key={i} value={i}>{i} años</option>);
        }
        return options;
    };

    return (
        <div className="relative container mx-auto max-w-xs pt-8 px-4 m-4 rounded-lg bg-black/10 ">
            <CloseIcon className="absolute w-7 h-7 top-1 right-1 text-slate-500" onClick={() => switchOpen(false)} />
            <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-500 text-md font-medium mb-2" htmlFor="checkin">
                        Check-in
                    </label>
                    <input
                        type="date"
                        id="checkin"
                        className="bg-white shadow appearance-none border rounded w-full h-11 py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                        value={checkin}
                        onChange={(e) => setCheckin(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-500 text-md font-medium mb-2" htmlFor="checkout">
                        Check-out
                    </label>
                    <input
                        type="date"
                        id="checkout"
                        className="bg-white shadow appearance-none border rounded w-full h-11 py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                        value={checkout}
                        onChange={(e) => setCheckout(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-500 text-md font-medium mb-2" htmlFor="adults">
                        Adults
                    </label>
                    <input
                        type="number"
                        id="adults"
                        className="shadow appearance-none border rounded w-full h-11 py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                        value={adults}
                        onChange={(e) => setAdults(parseInt(e.target.value))}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-500 text-md font-medium mb-2" htmlFor="children">
                        Children
                    </label>
                    <input
                        type="number"
                        id="children"
                        className="shadow appearance-none border rounded w-full h-11 py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                        value={children}
                        onChange={(e) => setChildren(parseInt(e.target.value))}
                    />
                </div>
                { (false && children > 0) && (
                    <div className="mb-4">
                        <label className="block text-gray-500 text-md font-medium mb-2" htmlFor="childAge">
                            Edad de los Niños
                        </label>
                        <div className="flex flex-wrap gap-4">
                            {Array.from({ length: children }, (_, index) => (
                                <select
                                    key={index}
                                    id={`childAge${index + 1}`}
                                    className="shadow appearance-none border rounded //w-1/4 h-11 py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    {generateAgeOptions()}
                                </select>
                            ))}
                        </div>
                    </div>
                )}
                { false && <div className="mb-4">
                        <label className="block text-gray-500 text-md font-medium mb-2" htmlFor="numRooms">
                            Numero de Habitaciones
                        </label>
                        <input
                            type="number"
                            id="numRooms"
                            className="shadow appearance-none border rounded w-full h-11 py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                            value={numRooms}
                            onChange={(e) => setNumRooms(parseInt(e.target.value))}
                            required
                        />
                    </div>
                }
                <div className="flex flex-col items-center justify-between">
                    <Button title={
                        <div className="flex justify-center items-center gap-2 p-2">
                            {icon}
                            <span className="whitespace-nowrap capitalize">
                                {"Check Availability"}
                            </span>
                        </div>
                    }
                        className={className + " !hover:bg-white/20"}
                        src={src}
                        onClick={() => handleSubmit()}
                    />
                </div>
            </form>
        </div>
    );
};
