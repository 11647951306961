import { capitalizeFirstLetter } from "../../commons/utils"

export default function Config({ lang, country }) {

    //console.log("CONFIG COMPONENT", lang, country )

    return (
        lang &&
        <div className="flex justify-center items-center text-black ">
            <div className="flex justify-center items-center px-2 rounded-full //border bg-blue-200 gap-2">
                <span className="text-xs">
                    { capitalizeFirstLetter(lang) }
                </span> 
                <img
                    src={`https://flagsapi.com/${country}/flat/24.png`}
                    alt={lang}
                />
            </div>
        </div>
    )
}