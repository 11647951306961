import { useEffect, useState } from "react";
import useData from "../../vio/context/useData";
import CloseIcon from "../commons/CloseIcon";
import { ArrowIcon, AssetsIcon, CameraIcon, ChatsIcon, FolderIcon, GaleryIcon, ImageTabletIcon, MenuIcon } from "./Icons";
import FullScreenImage from "../../commons/FullScreenImage";

const Assets = () => {
    let [{ switcher, className, transition }, setUi] = useData("ui/assets", "Assets")
    let [thumbnail, setThumbnail] = useState(true)
    let [tab, setTab] = useState("links")
    let [ fullScreen, setFullScreen ] = useState(false)
    let [assets] = useData("assets", "Assets")
    let [icons, setIcons] = useState({});

    let hasAssets = objectToArray(assets). length > 0
    let menu = <MenuIcon onClick={() => setSwitcher( switcher === 'open' ? "closed":"open")} />

    if (!hasAssets) {

        thumbnail = false
        switcher = "closed"
    }

    let setSwitcher = (state) => {

        if (state === true) {

            state = "preview";
            setThumbnail(true);

        } else if (state === false) {

            state = "closed";
            setThumbnail(false);

        } else if (state === "closed") {

            state = thumbnail ? "preview" : "closed";
        }

        setUi({ switcher: state })
    }

    const onSelect = (icon, opener = false, open) => {
        setIcons({ ...icons, [icon]: open === undefined ? !icons[icon] : open });

        setTab(icon === "Image" ? "gallery" : "links")
        opener && setSwitcher('open');
    };

    const onFullScreen = (src) => {
        setFullScreen(src)
    };

    let bgContainer = switcher === "preview" ? " bg-blue-200 pl-1.5" : "bg-black"

    let rootClassName = `mt-14 bg-black //!bg-red-400 ${className[switcher]} ${transition} ${bgContainer} ${fullScreen ? "z-50" : ""}`
    //hasAssets ? switcher : "closed"

    let { Image, ...links } = assets

    //console.log("ASSETS:", switcher, thumbnail)
    return (
        fullScreen ? (
            <FullScreenImage imageUrl={fullScreen} onClose={() => setFullScreen(false)} onFullScreen={onFullScreen} gallery={assets.Image}/>
        ) : (
            <div key="root" className={rootClassName}>
                <div key="content" className={`relative z-50 w-full h-screen //bg-red-400`}>
                    {switcher === "preview" ? (
                        <div key="thumbnails" className="relative">
                            <Thumbnails menu={menu} assets={objectToArray(assets)} onSelect={onSelect} setSwitcher={setSwitcher} />
                        </div>
                    ) : (
                        switcher === "open" ? (
                            <div key="containerItemsAssets" className="relative">
                                <div id="itemsAssets" key="itemsAssets" className={`fixed bg-black top-0 w-96 //bg-red-500 pb-48 overflow-y-auto scrollbar max-h-screen`}>
                                    <Tabs tab={tab} setTab={setTab} />
                                    {
                                        {
                                            links: <Links assets={objectToArray(links)} onClose={() => setSwitcher('closed')} icons={icons} setIcons={onSelect} />,
                                            gallery: <Gallery gallery={assets.Image} onFullScreen={onFullScreen}/>,
                                        }[tab]
                                    }

                                </div>
                                <CloseIcon className={`fixed top-1 right-2 h-8 w-8 text-white/50 hover:text-white`} onClick={() => setSwitcher('closed')} />
                            </div>
                        ) : ( // switcher = closed
                            <span className="fixed flex flex-col top-2 right-2 gap-2 md:right-6 //bg-pink-400">
                                {(hasAssets && !thumbnail) &&
                                    <>
                                        {menu}
                                        <span className="">
                                            <ThumbnailSwitch direction="down" setSwitcher={setSwitcher} />
                                        </span>
                                    </>
                                }
                            </span>
                        )
                    )}
                </div>
            </div>
        )
    )
    /*
    return (
        //switcher === "open" ? (
            <div id="assets" className={`flex-shrink-0 mr-8 bg-black ${className[switcher]}`}>
                <div id="itemsAssets" key="itemsAssets" className={`fixed flex flex-col w-full h-screen //bg-red-400 //flex-shrink-0 ${className}`}>
                    <div className="bg-orange-400 size-24">hola</div>
                    <div className="relative">
                        { (preview && showWindow) && (

                            )
                        }
                        { showWindow ? (
                                <Links assets={assetsArray} onClose={() => setShowWindow(false)} icons={icons} setIcons={handleItemClick} />
                            ) : (
                                <div className="flex flex-col gap-4">
                                    {assetsArray.map(([key, { icon, color }], index) => (
                                        <div
                                            key={key}
                                            className={`flex justify-center items-center h-11 w-11 p-2 rounded-full border border-transparent hover:border-white ${color}`}
                                            onClick={ () => handleItemClick(key, true, true)} // Agregamos el manejador de clic
                                        >
                                            {icon}
                                        </div>
                                    ))}
                                    { preview && (
                                        <span className="flex justify-center items-center h-11 w-11 hover:bg-white" onClick={() => setShowAssets(false)}>
                                            <ArrowIcon color={"#fff5"} direction="up" />
                                        </span>
                                    )}
                                </div>
                            )
                        }

                    </div>
                        { switcher && 
                            <CloseIcon className={`absolute top-1 right-1 h-8 w-8 text-white/50 hover:text-white`} onClick={() => setUi({ switcher:'open' })} />
                        }
                    </div>
            </div>
        / *) : (
            <div className="fixed top-8 -right-2 md:right-2 p-4">
                <span className="flex justify-center items-center h-11 w-11 rounded-2xl bg-white/5 hover:bg-white/20" onClick={() => setShowAssets(true)}>
                    <ArrowIcon className="h-8 w-8 p-2 rounded-full border border-transparent hover:border-white" color={"#fff5"} direction="down" />
                </span>
                { switcher && 
                    <CloseIcon className={`absolute top-1 right-1 h-8 w-8 text-white/50 hover:text-white`} onClick={() => setUi({ switcher:'closed' })} />
                }
            </div>
        )
        * /
    );
    */
};

let objectToArray = (obj) => Object.entries(obj)

function Gallery({ gallery, onFullScreen }) {

    console.log("GALLERY:", gallery)

    return ( gallery &&
        <div className="grid grid-cols-3 //sm:grid-cols-3 md:grid-cols-4 gap-4 p-4">
            {Object.entries(gallery.links).map(([key, { title }]) => {

                let src = key.startsWith("http") ? key : `https://quintabellahuatulco.com/${key}`

                return (
                    <div key={key} className=" gallery-image " onClick={() => onFullScreen(src)}>
                        <img src={src} alt={"Imagen"} className="//w-24 w-full h-full //aspect-3/4 object-cover rounded border-white/50 hover:border " />
                    </div>
                )
            })}
        </div>
    );
}

const ThumbnailSwitch = ({ direction, setSwitcher }) => {

    return (
        <span className="flex justify-center items-center rounded-2xl //border hover:bg-white/50 border-gray-500" onClick={() => setSwitcher(direction === "down" ? true : false)}>
            <ArrowIcon color={"#0007"} direction={direction} />
        </span>
    )
}
/*
                        <span className="absolute //top-0 left-0 flex items-center h-8 w-4 rounded-r-full bg-white/5 top-1/2 transform -translate-y-1/2" onClick={() => setShowWindow(false)}>
                            { false && <ArrowIcon color={"#fff7"} direction="right" /> }
                        </span>
*/

const Thumbnails = ({ menu, assets, setSwitcher, onSelect }) => {

    return (assets.length > 0 &&
        <div className="fixed flex flex-col gap-2 md:gap-2 //bg-red-400">
            <span className="flex justify-center items-center pt-2">
                {menu}
            </span>
            <div className="flex flex-col gap-3 md:gap-4">
                {assets.map(([key, { icon, color }], index) => (
                    <div
                        key={key}
                        className={`flex justify-center items-center h-11 w-11 p-2 rounded-full border border-transparent hover:border-white ${color}`}
                        onClick={() => onSelect(key, true, true)} // Agregamos el manejador de clic
                    >
                        {icon}
                    </div>
                ))}
            </div>
            <ThumbnailSwitch direction="up" setSwitcher={setSwitcher} />
        </div>
    )
}

const Tabs = ({ tab, setTab }) => {

    return (
        <div className="flex justify-center items-center gap-10 h-12 //px-10 //bg-green-800">
            <span className={`border-white hover:border-white/50 hover:border-b ${tab === "links" ? "border-b" : ""}`} onClick={() => setTab('links')} >
                <FolderIcon className="h-10 w-10 text-white" />
            </span>
            <div className={`border-white hover:border-white/50 hover:border-b ${tab === "gallery" ? "border-b" : ""}`} onClick={() => setTab('gallery')}>
                <ImageTabletIcon className="h-10 w-10 text-white" color="#888" />
            </div>
        </div>
    )
}
const Links = ({ assets, onClose, icons, setIcons }) => {



    return (
        <div className="relative text-white flex justify-star items-cente transition-transform ease-in-out duration-1000 transform translate-x-0">
            <div className="w-full h-screen rounded-l-2xl //bg-black/80 pt-0.5 px-4 md:px-6">
                <h2 className="text-2xl font-bold"></h2>
                <ul className="flex flex-col gap-5">
                    {assets ? assets.map(([key, props]) => {

                        let { name, icon } = props.white || props
                        let links = props.links


                        let isOpen = icons[key]
                        let linksArray = links !== undefined ? Object.entries(links) : [];

                        if (isOpen)
                            console.log("LINKS:", name, isOpen, links)

                        return (
                            <li key={key} className={``}>
                                <div key="title" className={`flex justify-start items-center gap-1 font-light rounded-lg hover:bg-white/10 cursor-default ${(isOpen ? props.color + " border border-white/30" : "")}`} onClick={() => setIcons(key)}>
                                    <div
                                        key={"icon"}
                                        className={`flex justify-center items-center h-8 w-8 //px-3 //mb-2 //rounded-full //bg-red-400 rounded-full p-1.5 `}
                                        onClick={null} // Agregamos el manejador de clic
                                    >
                                        {isOpen ? props.icon : icon}
                                    </div>
                                    <span key="name" className={`!text-white font-bold ${(isOpen ? (props.white ? " !text-black " : "text-white") : "")}`}>
                                        {name || "Otros"}
                                    </span>
                                </div>
                                {(isOpen && linksArray.length > 0) &&
                                    <div key="content" className="flex flex-col //gap-1 pl-2 font-normal">
                                        {linksArray.map(([src, { title }], index) => (
                                            <a key={src} href={src} target="_blank" className={`mt-1 py-0.5 px-1 mr-2 rounded-sm hover:bg-white/20 text-gray-200 font-light text-sm cursor-default `} >
                                                {title}
                                            </a>
                                        ))}
                                    </div>
                                }
                            </li>
                        )
                    })
                        : <div className="flex justify-center items-center h-16 w-16 rounded-full bg-white/5">Cargando...</div>
                    }
                </ul>
            </div>
        </div>
    );
};

/*
                            <a href={null} className="block py-0 hover:text-blue-400">
                                { null }
                            </a>
*/
export default Assets;