import { ImageIcon, FacebookIcon, TwitterIcon, InstagramIcon, TripAdvisorIcon, YouTubeIcon, BookIcon, WhatsAppSVG, MapIcon, PanoramicIcon, PDFIcon, LinkIcon, CameraIcon } from './Icons'
import updater from "../../vio/context/updater";

function getAsset(asset="Link", src, title="") {

    let a = {
        Image: {
            name: 'Gelería de Imagenes',
            icon: <ImageIcon />,
            color: "!bg-cyan-700",
            className: "!text-white",
        },
        ImageWhite: {
            name: 'Galería de Imagenes',
            icon: <ImageIcon color={"#ffffff22"}/>,
            color: "!bg-[#ffffff]",
            className: "!text-black",
        },
        Facebook: {
            name: 'Facebook',
            icon: <FacebookIcon />,
            color: "!bg-[#0866FF]",
            className: "",
        },
        Twitter: {
            name: 'Twitter',
            icon: <TwitterIcon />,
            color: "!bg-[#000000]",
            className: "",
        },
        Instagram: {
            name: 'Instagram',
            icon: <InstagramIcon />, // #c32aa3
            color: "bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 ",
            className: "",
        },
        Trip: {
            name: 'TripAdvisor',
            icon: <TripAdvisorIcon />,
            color: "!bg-[#34E0A1]",
            className: "!text-black",
        },
        TripWhite: {
            name: 'TripAdvisor',
            icon: <TripAdvisorIcon color="#ffffff" />,
            color: "!bg-[#34E0A1]",
            className: "!text-white",
        },
        You: {
            name: 'YouTube',
            icon: <YouTubeIcon />,
            color: "!bg-[#ff0000]",
            className: "",
        },
        Book: {
            name: 'Reservar',
            icon: <BookIcon />,
            color: "!bg-amber-400",
            className: "!text-black",
        },
        BookWhite: {
            name: 'Reservar',
            icon: <BookIcon color="#ffffff" />,
            color: "!bg-amber-400",
            className: "!text-white",
        },
        Whatsapp: {
            name: 'WhatsApp',
            icon: <WhatsAppSVG />,
            color: "!bg-[#25d366]",
            className: "",
        },
        Map: {
            name: 'Ubicación',
            icon: <MapIcon />,
            color: "!bg-red-700",
            className: "",
        },
        Panoramic: {
            name: 'Vista 360°',
            icon: <PanoramicIcon />,
            color: "!bg-indigo-600",
            className: "",
        },
        PDFIcon: {
            name: 'PDFs',
            icon: <PDFIcon />,
            color: "!bg-[#FA8072]",
            className: "",
        },
        Link: {
            name: 'Link´s',
            icon: <LinkIcon />,
            color: "!bg-sky-500",
            className: "",
        }
    }

    if(a[asset + "White"])
        a[asset].white = a[asset + "White"]

    updater(`assets/${asset}/links`)({ [src]: { title } })
    updater(`assets/${asset}`)({...a[asset]})
    updater(`assets`)({})

    let { icon, className, color } = a[asset]

    return [icon, `${className} ${color}`]
}

export default getAsset;