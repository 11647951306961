import SwiperSlider from "../../vio/components/SwiperSlider";
import Image from "./Image";
import Button from "./Button";
import { send } from "../../commons/messages";
import { alltrim, capitalizeFirstLetter } from "../../commons/utils";
import { useState } from "react";
import BookingForm from "./BookingForm";
import getAsset from "./getAssets";
import { CursorIcon } from "./Icons";
const tagRegExp = `<<.*?>>`

let firstTime = undefined
let counterExt = undefined
export default function MessageContent({ sender, message, state, id }) {

    let [ first, setFirst ] = useState(true)
    const sections = [];

    let conversation = [[]]
    let contents = [[]]
    let index = 0

    //console.log(`>>[${sender}]`, message)

    if (message === undefined)
        return null

    message = detectTitles(message)

    if (state === "writing")
        message = removeSubstrings(message)

    let extract = extractRows(message, state)

    //state === "close" && console.log("CLOSE:", extract)   

    let arrayRows = groupContiguos(extract)
    let counter = 1

    let abc = 0

    arrayRows.forEach((text, index) => {

        //console.log("TEXT-----------", text)

        /*
        if(index === arrayRows.length - 1 && state !== "close" && sender === "assistant") {

            return
        }
        */

        if (typeof text === "string") {

            console.log("TEXT", text)
            //if((text.startsWith("* ![") || text.startsWith("![") || text.startsWith("* [") || text.startsWith("[")) && state !== "close") {
            if(( ((text.startsWith("- ") || text.startsWith("* ")) && text.length == 2) || text.startsWith("!") || text.startsWith("* ![") || text.startsWith("- ![") || text.startsWith("- ![") || text.startsWith("![") || text.startsWith("* [") || text.startsWith("- [") || text.startsWith("[")) && index === arrayRows.length - 1 && state !== "close" && sender === "assistant") {

                return
            }

            //text = text.replace(/\. /g, '')

            //text = convertirEnComponentes(dividirTextoPorEnlaces(text))

            //text = text.split("\n");

            //text = reemplazarConBoton(text)

            function getStrong(description, text, link, paragraph=false, bg=true) {

                let txt

                if(firstTime === undefined) {

                    firstTime = id
                    counterExt = abc
                }

                if (description === undefined && bg !== true) {
                    txt = alltrim(text.slice(1).replace(/\*\*/g, ""))

                } else {

                    description = description === undefined ? "" : alltrim(description.replace(/\*\*/g, ""))

                    /*
                    if(paragraph === false && description === "") {
                        description = link
                        link = undefined
                    }
                    */

                    //console.log("DESCRIPTION", first, firstTime, id, firstTime === id && first)

                    txt = <div className="relative">
                        { link !== undefined && <span className={`font-normal cursor-default rounded //px-1 hover:bg-white/10 ${ bg ? "text-blue-400 hover:bg-blue-100 cursor-pointer":""} ${paragraph ? "uppercase":""}`} onClick={() => send(link)}>{link}</span> }
                        { description && <span className={`${paragraph ? "block font-normal text-black":"pl-1"}`}> { description }</span> }
                        { (firstTime === id && counterExt === abc && first) && <span className="z-50 absolute w-48 top-4 p-3 left-2 border-2 border-white //border-dashed rounded-3xl rounded-tl-md bg-amber-400/90 text-black font-semibold text-sm">
                            <div className="flex justify-center items-center gap-2" onClick={() => setFirst(false)}>
                                <span>
                                    You can click on the blue words to turn them into a question
                                </span>
                                <span className="p-4 rounded-xl bg-black text-white cursor-default hover:bg-black/20">
                                    OK
                                </span>
                            </div>
                        </span>}
                    </div>
                }

                abc++

                return txt
            }

            if (Array.isArray(text)) {
                text.forEach((item, ind) =>
                    addSection(<div key={"text" + ind}>{item}</div>)
                )
            } else {

                //console.log("ITEM", text[0] === "-", text)
                
                let item = text
                let isNew = false

                if(text[0] === "-") {

                    item = <div className={`flex ${counter === 1 ? " mt-2":""}`}>
                        <span className="//font-thin ">
                            { text.slice(1) }
                        </span>
                    </div>
                    counter++

                    //addSection(<div key={getKey()}>{ item }</div>)

                } else if(text[0] === "*") {

                    //console.log("TEXT", text)

                    if(text.length > 1 ) {

                        let [link, description] = text.slice(1).split(":")
                        link = alltrim(link.replace(/\*\*/g, ""))

                        let txt = getStrong(description, text, link)

                        item = <div className={`//pl-2 flex //pb-1 ${counter === 1 ? " mt-2":""}`}>
                            { false && <span className="text-slate-400 //ml-2 font-light">
                                { counter } -
                            </span>
                            }
                            <span className="//font-thin">
                                { txt }
                            </span>
                        </div>
                        counter++
                    } else {

                        item = null
                    }
                    //addSection(<div key={getKey()}>{ item }</div>)

                } else if (text[0] === "•" && text[1] !== "•") {

                    let [link, description] = text.slice(1).split(":")
                    link = alltrim(link.replace(/\*\*/g, ""))
                    let txt = getStrong(description, text, link, true, true)

                    item = <div
                            className="flex items-center gap-1 //pb-1 //my-2 //rounded-md //font-thin border-b-0 border-blue-200 //bg-blue-200 cursor-default"
                            onClick={() => send(txt)}
                        >
                            { false && <span className="w-5">
                                <CursorIcon/>
                            </span>
                            }
                            <span className="//bg-blue-200 rounded-md //px-2 //hover:bg-blue-100">
                                { txt }
                            </span>
                    </div>

                    counter = 1
                    isNew = true

                }

                if(item !== null) {

                    addSection(<div key={getKey()}>{ item }</div>, isNew)
                }
            }

        } else if (Array.isArray(text) && state === "close") {

            if (text.length > 1) {

                let images = []
                text.forEach(it => {
                    let [tag, component] = getContent(it)
                    //console.log("TAG", tag)

                    if (tag === 'image') {
                        images.push(component)

                    } else {
                        addSection(component, false, "text")
                    }

                    return component
                })

                if(images.length > 0) {
                    addSection(images, false, "image")
                }
                //addSection(<div key={getKey()} className="flex justify-center max-w-full //bg-pink-400"><SwiperSlider items={items} index={index} /></div>, false, "image")

            } else {

                let [tag, component] = getContent(text[0], state)
                //addSection(<div key={getKey()} className="flex justify-center">{component}</div>, false, "image")

                if(tag === "image")
                    addSection(component, false, "image")
                else
                    addSection(component, false, "text")
            }
        }
    })

    function reemplazarConBoton(texto) {
        const regex = /\|\|\| (.+)$/;
        const match = texto.match(regex);

        if (match) {
            const keywords = match[1].split(', ');

            keywords.forEach(keyword => {
                const botonJSX = <button key={keyword}>{keyword}</button>;
                texto = texto.replace(keyword, botonJSX);
            });
        }

        return <>{texto}</>;
    }

    function SimpleSlider({ items }) {
        let [ current, setCurrent ] = useState(0)

        ////console.log("SIMPLE SLIDER", Array.isArray(items) )

        return (
            <div className="flex justify-center max-w-full //bg-pink-400">
                { items[current] }
            </div>
        )
    }


    function getContent(string, state) {

        ////console.log("GET CONTENT", string)
        var component = null
        var className = ""
        let props = extractAttributes(string)
        var { tag, src = "", title = "Link" } = props || {}

        ////console.log("GET CONTENT", tag, src, title)

        let icon = null
        let content = null
        let Container = ({ children }) => <div className="flex justify-center"> {children} </div>
        ////console.log("EXTRACT MATCHES", tag, src, title )
        //onClick={title ? () => send(title, "") : null}

        const Booking = ({ icon, className, src }) => {

            let [open, switchOpen] = useState(false)

            return (
                open ? (
                    <div className="flex flex-col items-center justify-center">
                        <BookingForm src={src} switchOpen={switchOpen} className={className} icon={icon} />
                    </div>
                ) : (
                    <Button title={
                        <div className="flex justify-center items-center gap-2 p-2 ">
                            {icon}
                            <span className="whitespace-nowrap capitalize">
                                {title || "Link"}
                            </span>
                        </div>
                    }
                        className={className + " !hover:bg-white/20"}
                        onClick={() => switchOpen(true)}
                    />
                )
            )
        }

        if (src.endsWith('.jpg') || src.endsWith('.jpeg') || src.endsWith('.png') || src.endsWith('.gif')) {

            if (src.startsWith !== "https://www.quintabellahuatulco.com/") {
                let index = src.indexOf("recursos")
                src = "https://www.quintabellahuatulco.com/" + src.slice(index)
            }

            ////console.log("IMAGE", src)

            let [a, b] = getAsset('Image', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.facebook.com")) {
            let [a, b] = getAsset('Facebook', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.twitter.com") || src.startsWith("https://twitter.com")) {
            let [a, b] = getAsset('Twitter', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.instagram.com")) {
            let [a, b] = getAsset('Instagram', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.tripadvisor.com")) {
            let [a, b] = getAsset('Trip', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.youtube.com")) {
            let [a, b] = getAsset('You', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://direct-book.com")) {
            let [a, b] = getAsset('Book', src, title)
            icon = a
            className = b
            content = <Booking icon={icon} className={className} src={src} />
        }
        else if (src.startsWith("https://api.whatsapp.com")) {
            let [a, b] = getAsset('Whatsapp', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.google.com.mx/maps")) {
            let [a, b] = getAsset('Map', src, title)
            icon = a
            className = b
        }
        else if (src.startsWith("https://www.mexicotravelclub.com/toursvirtuales")) {
            let [a, b] = getAsset('Panoramic', src, title)
            icon = a
            className = b
        }
        else if (src.endsWith('.pdf')) {
            let [a, b] = getAsset('PDFIcon', src, title)
                icon = a
            className = b
        }
        else if (src.endsWith('.html') || src.endsWith('.com') || src.endsWith('.mx')) {
            let [a, b] = getAsset('Link', src, title)
            icon = a
            className = b
            //className = "!bg-red-400 text-white"
        }

        if (!src.startsWith("http"))
            src = "https://www.quintabellahuatulco.com/" + src
        //} else if(src.startsWith("https://www.quintabellahuatulco.com/https://www.quintabellahuatulco.com")) {
        //    src = src.replace("https://www.quintabellahuatulco.com/https://www.quintabellahuatulco.com", "https://www.quintabellahuatulco.com")

        props && (
            component = tag === 'image'
                ? <Image {...{...props, src}} key={getKey()} onClick={title ? () => send(title, "") : null}/>
                : <Container>
                    {content ||
                        <Button title={
                            <div className="flex justify-center items-center gap-2 p-2 //bg-green-400">
                                <div className="shrink-0 //bg-red-400">
                                    {icon}
                                </div>
                                <div className="//whitespace-nowrap font-semibold text-white">
                                    {title || "Link"}
                                </div>
                            </div>
                        }
                            url={src}
                            className={className}
                            classNameA={" //!hover:bg-opacity-80"}
                        />
                    }
                </Container>
        )

        return [tag, component]

    }

    function onErrorImage(e) {
        //console.log("ERROR IMAGE", e.target.src)
        let index = e.target.src.indexOf("recursos")

        let newSrc = "https://www.quintabellahuatulco.com/" + e.target.src.slice(index)
        e.target.src = newSrc;

    }

    function addSection(item, isNew = false, type="text") {
        
        //console.log("ITEM", item)

        if(isNew) {
            conversation.push([])
        }

        if(type === "text") {

            conversation[conversation.length - 1].push(item)

        } else if(type === "image") {

            if(contents[conversation.length - 1] === undefined) {
                contents[conversation.length - 1] = []
            }

            if(Array.isArray(item)) {

                contents[conversation.length - 1] = [ ...contents[conversation.length - 1], ...item ]
            } else {

                contents[conversation.length - 1] = [ ...contents[conversation.length - 1], item ]
            }
        }

        /*
        sections.push(<div className="bg-white rounded-2xl p-3 mb-2">
            { item }
        </div>)
        */
    }

    function getKey() {

        return sections.length + 1
    }

    let items = []

    conversation.forEach((section, ind) => {

        let className = sender === "assistant" ? "bg-white rounded-tl-md" : "rounded-tr-md bg-blue-600"

        if(section.length > 0) {
            items.push(
                <div key={ind} className={`rounded-2xl ${className}`}>
                    {[ section.map((item, index) => {

                            return ( item &&
                                <div key={index} className=" first:pt-3 last:pb-3 py-1 px-4 //bg-red-400 ">
                                    { item }
                                </div>
                            )
                        }),

                    ]}
                </div>
            )

            items.push((contents[ind] !== undefined  && contents[ind].length > 0) ? (
                    <div key={ind+"img"} className={`rounded-2xl overflow-hidden ${className} !bg-blue-100`}>
                        { <SwiperSlider items={contents[ind]} index={ind} /> }
                    </div>
                ) : null
            )
        }
    })

    return (
        <div key="messageList" className="flex flex-col gap-2">
            { items }
        </div>
    )
}

function convertUrlsToTags(markdownText) {
    // Expresión regular para encontrar URLs en formato markdown
    //const urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/g;
    //const urlRegex = /(?:!\[([^\]]+)\]\()|(\[([^\]]+)\]\()+(https?:\/\/[^\s\)]+)\)/g;
    //const urlRegex = /(?:!\[([^\]]+)\]\()|\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/g;
    //const urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)|!\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/g;
    //const urlRegex = /\[([^\]]+)\]\(([^)]+)\)/g
    //const urlRegex = /!\[([^\]]+)\]\(([^)]+)\)|\[([^\]]+)\]\(([^)]+)\)/g;

    //const urlRegex0 = /- (.+?): \!\[(.+?)\]\((.+?)\)/g;
    //const urlRegex0 = /([^:]+):\n\!\[([^)]+?)\]\(([^)]+?)\)/g;

    /*
    const urlRegex0 = /- ([^:]+):\n\!\[([^)]+?)\]\(([^)]+?)\)/g;

    markdownText = markdownText.replace(urlRegex0, (match, title, text, url) => {
        console.log("MATCH 0::::", match, title, url, text)
        const tag = url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') ? 'image' : 'link';
        title = alltrim( title || (text || ""))
        return ` <<${tag} src='${url}' title='${capitalizeFirstLetter(title)}'>>`;
    });
    */
    /*
    const urlRegex = /!\[(.+?)\]\(([^)]+?)\)/g;    
    
    // Reemplazar URLs con el formato deseado
    const convertedText = convertedText0.replace(urlRegex, (match, text, url) => {
        console.log("MATCH", match, url, text)
        const tag = url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') ? 'image' : 'link';
        const title = text ? text.trim() : ""
        return `<<${tag} src='${url}' title='${capitalizeFirstLetter(title)}'>>`;
    });
    */

    const urlRegex3 = /\!\[(.+?)\]\(([^)]+?)\)/g;

    markdownText = markdownText.replace(urlRegex3, (match, text, url) => {
        //console.log("MATCH", match, url, text)
        const tag = url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') ? 'image' : 'link';
        const title = text ? text.trim() : ""
        return `<<${tag} src='${url}' title='${capitalizeFirstLetter(title)}'>>`;
    });

    const urlRegex2 = /\[(.+?)\]\(([^)]+?)\)/g;

    markdownText = markdownText.replace(urlRegex2, (match, text, url) => {
        //console.log("MATCH", match, url, text)
        const tag = url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') ? 'image' : 'link';
        const title = text ? text.trim() : ""
        return `<<${tag} src='${url}' title='${capitalizeFirstLetter(title)}'>>`;
    });


    const regex = /- (.+?): <<(.+?)>>/g;
    //const regex = /- (.*?): <<(.*?) src='(.*?)' title='(.*?)'>>/g;

    markdownText = markdownText.replace(regex, (match, description, string) => {

        let props = extractAttributes(`<<${string}>>`)
        const { tag, src = "", title = "Link" } = props || {}
        //console.log("MATCH::", string, tag, src, title)

        if (description.toLowerCase() === title.toLowerCase()) {
            description = ""
        }

        /*const tag = url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') ? 'image' : 'link';
        const title = description ? description.trim() : ""
        */

        return `${description}<<${tag} src='${src}' title='${capitalizeFirstLetter(title)}'>>`;
    });

    //console.log("CONVERTED TEXT:", markdownText, new Date().getTime() - time)

    return markdownText
}

function extractRows(string, state) {

    const result = [];

    string = convertUrlsToTags(string)

    string = string.replace(/- <</g, ' <<')
    string = string.replace(/>>\n <</g, '>> <<')

    //console.log("string", string)

    const extractRows = (fragment) => {
        fragment = alltrim(fragment)
        if (fragment) {
            fragment = fragment.replace(/^\./g, '')

            result.push(fragment);
        }
    };

    const regexp = new RegExp(tagRegExp, "g")

    //console.log("REGEXP", string.split("\n"))

    string.split("\n").forEach((line) => {

        let start = 0;
        var match

        while ((match = regexp.exec(line)) !== null) {

            //console.log("LINE", line)
            // Add the line fragment before the match
            extractRows(line.substring(start, match.index));
            // Add the match (including the delimiters)
            extractRows(match[0]);
            // Update the start position
            start = match.index + match[0].length;
        }

        // Add the fragment after the last match
        extractRows(line.substring(start));

    })

    //console.log("RESULT", result)

    return result;
}

function detectTitles(inputString) {

    return inputString//inputString.replace(/(\n?)\s*- \s*(\w+)/g, '\n- $2');
}

function groupContiguos(array) {
    const groupedArray = [];
    let currentGroup = [];

    const regexp = new RegExp(tagRegExp)

    for (const item of array) {
        if (item.match(regexp)) {
            currentGroup.push(item);
        } else {
            if (currentGroup.length > 0) {
                groupedArray.push(currentGroup);
                currentGroup = [];
            }
            groupedArray.push(item);
        }
    }

    if (currentGroup.length > 0) {
        groupedArray.push(currentGroup);
    }

    return groupedArray;
}

function removeSubstrings(inputString) {
    // Use regular expression to remove substrings starting with "<<"
    inputString = inputString.replace(/<<[^>]*>>/g, '');

    return inputString.replace(/<<[^>]*/g, '');

}

function extractAttributes(string) {

    return getMatches(">") || getMatches()

    function getMatches(endRegExp = "") {

        const regex = `<<(\\w+)\\s+src=['"]([^'"]+)['"](?:\\s+title=['"]([^'"]*)['"])?>` + endRegExp;
        const matches = string.match(new RegExp(regex));

        if (matches) {

            const [, tag, src, title = ""] = matches;
            return { tag, src, title };

        } else {

            return null;
        }
    }
}