import React, { useEffect, useState } from 'react';
import Header from '../components/widget/Header';
import Message from '../components/widget/Message';
import Footer from '../components/widget/Footer.js';
import useData from '../vio/context/useData';
import { getConversation } from '../commons/messages';
import Firefly from '../components/widget/Firefly';
import effects from '../components/widget/effects';
import Assets from '../components/widget/Assets';
import updater from '../vio/context/updater';
import useEmit from '../vio/context/useEmit'
import * as channels from '../commons/channels'
import Total from '../components/widget/Total.js';

const Widget = () => {

    let [{ bg }] = useData("ui/main", "Widget")
    //let [ mode, setMode ] = useData("dat", "Widget")

    useEmit('conversation', {
        actions: {
           ...channels
        }
    })

    updater(`usageAmount`)(0, "r")
    updater(`counterMessages`)(0, "r")

    useEffect(effects, []);

    return (
        <div id="root" key="root" className={`relative h-full w-screen overflow-hidden ${bg} text-white text-sm font-poppins `}>
            <Firefly items={0} />
            <div id="main" key="main" className='flex overflow-hidden'>

                <div id="content" key="content" className="relative flex-1 overflow-hidden //bg-red-400">
                    <Header/>
                    <Conversation/>
                    <Footer/>
                </div>
                { <Assets/> }
            </div>
        </div>
    );
}



/*

                    <div id="footer" key="footer" className='z-10 fixed bottom-0 w-full transform translate-y-0 transition-transform duration-250 bg-slate-800'>
                        <Footer/>
                    </div>

            <div id="content" key="content" className="relative flex flex-1 overflow-y-auto overflow-x-hidden scrollbar">
                <Conversation />
            </div>


            <div id="content" key="content" className="relative flex flex-1 overflow-y-auto overflow-x-hidden scrollbar">
                <Conversation />
                <Assets />
            </div>
            <div id="footer" key="footer" className='z-10 fixed bottom-0 w-full transform translate-y-0 transition-transform duration-250 bg-slate-800'>
                <Footer/>
            </div>

                            <div id="header" key="header" className='bg-slate-800'>
                    <Avatar onClick={() => setOpen(!open)} />
                </div>

*/

const Conversation = () => {

    let [{px, py }] = useData("ui/conversation", "Conversation")
    let [messages=[], setMessages] = useData("messages", "Conversation")
    messages.length === 0
        && setMessages(getConversation())

    return ( messages.length > 0 && (
            <div id="conversation" key="conversation" className={`${px} ${py} overflow-hidden gap-2 font-normal`}>
                <Message/>
            </div>
    ))
}

/*
<div id="endConversation"/>
    { messages.reverse().map((mensaje, index) => (
        <MessageBubble key={index} sender={mensaje.sender} text={mensaje.text} avatar={mensaje.avatar} />
    ))}
*/

const MessageBubble = ({ sender, text, avatar }) => {
    const bgColor = sender === 'user' ? 'bg-slate-800' : '';
    const bubbleAlignment = sender === 'user' ? 'mt-2 !bg-amber-400 border border-amber-400 text-black font-bold' : ' mt-2 !bg-slate-700 border border-slate-600';

    return (
        <div className={`flex items-start justify-start gap-1 md:gap-2 mb-2`}>
            <div className={`flex-shrink-0 w-7 h-7 rounded-full bg-gray-500 flex items-center justify-center text-center text-xs ${bubbleAlignment}`}>{avatar}</div>
            <div className={`rounded-lg p-2 ${bgColor} text-white w-full`}>
                {text}
            </div>
        </div>
    );
};

export default Widget;


/*

        <div className="flex h-screen bg-red-900 text-white">
            <div className="flex-1 overflow-hidden relative">
                <div className="overflow-y-auto p-4 bg-gray-800 h-full">
                    {messages.map((mensaje, index) => (
                        <MessageBubble key={index} sender={mensaje.sender} text={mensaje.text} avatar={mensaje.avatar} />
                    ))}
                </div>

                <div className="fixed top-0 right-4 w-16 p-4 bg-gray-900">
                    <div className="text-white text-xs font-bold mb-2">A</div>
                    <div className="text-white text-xs font-bold mb-2">B</div>
                    <div className="text-white text-xs font-bold mb-2">C</div>
                    <div className="text-white text-xs font-bold mb-2">D</div>
                </div>
            </div>
        </div>

*/