import { useState } from "react"
import { reset, send } from "../../commons/messages"
import Shortcuts from "./Shortcuts"
import { getObjectStorage, remStorage } from "../../commons/store"
import useData from "../../vio/context/useData"
import CloseIcon from "../commons/CloseIcon"
import { SendIcon } from "./Icons"
import Total from "./Total"

const useTw = () => ({
    editb: `
        flex justify-between items-center
        min-w-full
        px-2
        border-t
        border-gray-400
        dark:border-gray-600
    `,
    textarea: `
        block
        min-w-full
        text-md
        text-gray-600
        dark:text-white
        !bg-transparent
        rounded-br-3xl
        focus:border-blue-500
        dark:border-l-gray-600
        dark:placeholder-gray-400
        outline-none
        resize-none

        dark:focus:ring-blue-500
        dark:focus:border-blue-500
    `,
})

export default function Footer() {

    const tw = useTw()
    let [{ switcher, resize }] = useData("ui/assets", "Footer");
    let [{ main, footer }] = useData("ui", "Footer")
    let { bg } = main
    let { height } = footer
    let pr = resize[switcher]

    const [message, setMessage] = useState("")
    let [ shortcuts, setShortcut ] = useData("shortcuts", "Footer")

    let { title, shortcut } = getObjectStorage("shortcuts", {})

    //style={{ fill: '#38a169' }}

    /*
    CURP 
    ACTA DE NACIMIENTO
    CARTILLA DE VACUNACION
    TIPO SANGUINEO
    */
    // 3900

    //console.log(">> SHORTCUTS", shortcuts, shortcut)
    let sender = "user"
    const bgColor = sender === 'user' ? '' : 'bg-slate-800';
    const bubbleAlignment = sender === 'user' ? 'mt-1.5 bg-amber-400 border border-amber-400 text-black font-bold' : '!bg-slate-700 border border-slate-600';
    const z = switcher === "preview" ? "z-20": "z-30"

    return (
        <div id="footer" className={`${z} fixed bottom-0 left-0 w-full border-t border-white/5 bg-slate-900 ${height} ${pr} //bg-purple-500`}>
            <div className="relative w-full h-full items-center justify-center">
                { /* <Total/> */ }
                { title &&
                    <div key="1" className={"absolute -top-7 left-6 flex justify-center items-center //pt-2 //pl-2 border border-white/20 rounded-lg bg-black"}>
                        <span className="relative flex justify-center items-center gap-1 pl-5 pr-2 py-1 rounded-full text-white">
                            { shortcut }
                            <CloseIcon className="w-5 h-5 text-gray-400" onClick={removeShortcut}/>
                        </span>
                    </div>
                }
                <div className={`z-30 relative flex items-center //bg-red-400 pl-2 md:pl-6 gap-0 h-full //bg-yellow-700 //${bg}`}>
                    <div key="textarea" className={`relative rounded-lg px-3 py-2  w-full bg-white/5 //bg-yellow-700`}>
                        <textarea rows="1" placeholder="Ask in your language" className={tw.textarea} value={message} onKeyUp={onKeyUp} onChange={e => setMessage(e.target.value)} />
                        <SendIcon ready={message} onClick={submit}/>
                    </div>
                    <div key="shortcuts" className=" w-24 px-2 uppercase //pl-3 //w-full //h-full md:w-24 //bg-red-400" onClick={() => reset()}>
                        <span className="p-2 px-3 rounded-lg bg-slate-700 cursor-default hover:bg-white/40">
                            Restart
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
/*
                        <textarea rows="1" placeholder="Pregunta... (Ask in your language)" className={tw.textarea} value={message} onKeyUp={onKeyUp} onChange={e => setMessage(e.target.value)} />
//<Shortcuts submit={submit} setMessage={setMessage}/>

                <div key="user" className="flex w-full items-start pr-0 rounded-full gap-1 md:gap-2 bg-black //border">


                    <div key="avatar" className={`flex-shrink-0 w-7 h-7 rounded-full flex items-center justify-center text-center text-xs ${bubbleAlignment}`}>{"U"}</div>

            <div key="2" className="flex bg-transparent justify-between w-full px-4 ">
                <div className={"flex grow pr-2 h-full"}>
                    <textarea rows="2" placeholder="Pregunta... (ask in your language)" className={tw.textarea} value={message} onKeyUp={onKeyUp} onChange={e => setMessage(e.target.value)} />
                </div>
                <div className={"flex justify-center items-center gap-4"}>
                    <SendIcon/>
                    <Shortcuts submit={submit} setMessage={setMessage}/>
                </div>
            </div>
*/


    function onKeyUp(e) {

        if (e.keyCode === 13) {
            submit()
        }
    }

    function removeShortcut() {

        setShortcut({}, "r")
        remStorage("shortcuts")
    }

    function submit(txt, type) {

        setMessage("")
        send(txt || message, type)
    }
}