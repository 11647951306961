import CloseIcon from "../components/commons/CloseIcon";

export default function FullScreenImage ({ imageUrl, alt, onClose, gallery, onFullScreen }) {

    let array = Object.entries(gallery.links)

    return (
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black //bg-opacity-75 z-50">
        <div className="w-full h-full flex flex-col justify-between //bg-red-400">
          <div className="max-h-full //max-w-full flex justify-center //bg-red-400 //object-cover overflow-hidden">
            <img src={imageUrl} alt={alt} className="//w-full //h-screen object-cover //rounded-2xl //border" />
          </div>
          <div className="flex justify-center h-20 space-x-4 //py-2 mt-2 //bg-white/10 overflow-x-auto scrollbar">
            {array && array.map(([key, { title }], index) => {

                let src = key.startsWith("http") ? key : `https://quintabellahuatulco.com/${key}`

                return (
                    <img
                        key={src}
                        src={src}
                        alt={`${title}`}
                        className="w-16 h-16 object-cover  rounded  cursor-pointer hover:opacity-50"
                        onClick={() => onFullScreen(src)}
                    />
                )
            })}
          </div>
        </div>
            <CloseIcon className="absolute top-4 right-4 h-8 w-8 bg-black/20 rounded-2xl text-white hover:text-white" onClick={onClose} />
      </div>
    );
};