import ctx from "./ctx";

export default function getSocket( name, props={} ) {

    /*
    const { actions } = props

    if(actions)
        ctx.sockets.default.actions = { ...ctx.sockets.default.actions, ...actions}
    */

    return ctx.sockets.default
}