
function setStorage(key, value) {
    localStorage.setItem(key, value);
}

function getStorage(key) {

    return localStorage.getItem(key);
}

function updStorage(key, value) {

    localStorage.setItem(key, value);
}

function remStorage(key) {

    localStorage.removeItem(key);
}

function setObjectStorage(key, objeto) {

    const stringObj = JSON.stringify(objeto);
    localStorage.setItem(key, stringObj);
}

function getObjectStorage(key, defaultObj = {}) {

    const stringObj = localStorage.getItem(key);

    if (stringObj === null || stringObj === undefined) {

        setObjectStorage(key, defaultObj);
        return defaultObj;
    }

    return JSON.parse(stringObj);
}

export { setStorage, getStorage, updStorage, remStorage, setObjectStorage, getObjectStorage }