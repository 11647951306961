import useData from "../../vio/context/useData"
import Total from "./Total"

export default function Header({}) {

    let [{ switcher, resize }] = useData("ui/assets", "Header");
    let [{ main, header }] = useData("ui", "Header")
    let { bg } = main
    let { minHeight } = header
    let pr = resize[switcher]

    return (
        <div id="header" key="header" className={`z-40 fixed top-0 left-0 ${minHeight} w-full ${pr} //${bg} pb-2 `} >
            <div className="h-full w-full flex justify-center items-center !bg-blue-400 ">
                <div key="name" className={`whitespace-nowrap text-lg md:text-2xl font-greatvibes`}>
                    <img src={`/logo-white.png`} className=" h-12 " alt="Clic" />
                </div>
            </div>
        </div>
    )
    //<img className={tw.image} src={`https://widget.zerendipia.com/offers/sm-user${index}.jpg`} alt="Clic" onClick={onClick} />
}

/*
<div key="name" className={`whitespace-nowrap text-lg md:text-2xl font-greatvibes`}>
    🏝️ Hotel Quinta Bella 🏝️
</div>

{
                !maximized  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 dark:text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            }
            <div key="avatar" className={tw.bgImage + (maximized ? " " : " //absolute //top-0 //left-2 ")}>
                { false && <img className={tw.image + (maximized ? " w-32 h-32 " : " w-10 h-10 ")} src={`https://widget.zerendipia.com/offers/hlogo-white-qb.png`} alt="Clic" onClick={onClick} /> }
            </div>
*/