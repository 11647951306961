import { useState } from "react"
import FullScreenImage from "../../commons/FullScreenImage"


export default function Image({ src, title, onClick }) {

    let [ fullScreen, setFullScreen ] = useState(false)

    //src = `https://www.quintabellahuatulco.com/` + src

    src = src.startsWith("http") ? src : `https://quintabellahuatulco.com/${src}`

    return (
        <img
            key={"photo" + src}
            className="object-cover //border-2 //aspect-video cursor-pointer"
            src={src}
            onClick={() => null}
        />
    )

    /*
    return (
        fullScreen ? (
            <FullScreenImage
                imageUrl={fullScreen}
                alt={title}
                onClose={() => setFullScreen(false)}
                gallery={{ links: { [src]: { title } } }}
                onFullScreen={setFullScreen}
            />
        ) : (
            <div key={src} className={'flex flex-col justify-start //bg-red-400 items-center rounded py-4 pb-4 md:py-0 md:pb-4 //w-full md:w-80 aspect-square ' + (title ? "//h-108":" //pt-4 //h-108")}>
                {
                    title &&
                    <span className="m-1 px-2 py-1 rounded-full border border-gray-500 hover:bg-gray-200 text-black cursor-pointer" onClick={onClick}>
                        {title}
                    </span>
                }
                <img
                    key={"photo" + src}
                    className=" //w-96 h-128 //aspect-3/4 object-cover rounded-xl cursor-pointer border-gray-200 border-2 hover:border"
                    src={src}
                    onClick={() => null}
                />
            </div>
        )
    )
    */
}



