import useSocket from './vio/libs/useSocket.js'
import Widget from './routes/Widget.js'
import Error from './routes/Error.js'
import { reset } from './commons/messages.js'

var currentScript = document.currentScript

function App() {

    const appId = (currentScript && currentScript.getAttribute("appId")) || "v7OtbHYXlCkLiP4qJsErA"

    //console.log(appId, document.location.origin)
    console.log("session _id", localStorage.getItem('session_id'))

    var { ready, authorized } = useSocket({
        onOpen: e => {
            e.emit('authorization', {
                origin: document.location.origin,
                appId,
            })
        },
        actions: {
            authorization: (e, data) => {
                e.setAuthorization(data)
            }
        },
    })
    //authorized = true

    return ( ready && 
        <>
            { authorized && <Widget/> }
            <Error/>
        </>
    )
}

export default App;

//"homepage": "widget/",