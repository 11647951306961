import { addClass, removeClass, addEventListener } from './../../commons/navigator';

const effects = () => {

    let body = document.getElementById('body');
    let header = document.getElementById('header')
    let footer = document.getElementById('footer')
    let timeoutId;

    /*
    let handleScroll = () => {
        clearTimeout(timeoutId);

        removeClass(header, '-translate-y-0');
        addClass(header, '-translate-y-full');

        removeClass(footer, "translate-y-0");
        addClass(footer, "translate-y-full");

        timeoutId = setTimeout(() => {
            removeClass(header, '-translate-y-full');
            addClass(header, '-translate-y-0');

            removeClass(footer, "translate-y-full");
            addClass(footer, "translate-y-0");
        }, 250);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {

        window.removeEventListener('scroll', handleScroll);
    }
    */
}



export default effects;