import setState from './setState'

export default function updater(path, o="a", metadata) {

    return (value, op) => setState(path, value, op || o, metadata)
}

    /*
    switch (op) {
        case "a": // append (default)
            ctx.data[data] = getState(ctx.data[data] || value, value)
            break

        case "d": // delete
            delete ctx.data[data][value]
            ctx.data[data] = getState(ctx.data[data] || value)
            break

        default: // ['r', '*'] = replace
            ctx.data[data] = getState(value)
            break
    }
    */
