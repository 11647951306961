import ctx from './ctx'

export default ({ context, children }) => {

    ctx.data = context.data
    ctx.sockets = context.sockets

    return (
        <>
            { children }
        </>
    )
}