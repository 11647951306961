import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import context from './data/app';
import reportWebVitals from './reportWebVitals';
import Context from './vio/context/Context';

const rootElement = document.createElement("div")
document.body.appendChild(rootElement)

const root = ReactDOM.createRoot(rootElement);
root.render(
  //<React.StrictMode>
  <Context context={context}>
      <App/>
  </Context>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
