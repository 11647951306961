export default {
    "ab": {
      "lang": ["абхазский", "Аҧсшәа"],
      "countries": {
        "ge": "Аҧсны"
      }
    },
    "aa": {
      "lang": ["Afaraf", "Afar"],
      "countries": {
        "et": "Etiopia",
        "dj": "Djibouti"
      }
    },
    "af": {
      "lang": ["Afrikaans"],
      "countries": {
        "za": "Suid-Afrika"
      }
    },
    "ak": {
      "lang": ["Akan"],
      "countries": {
        "gh": "Ghana"
      }
    },
    "sq": {
      "lang": ["shqip"],
      "countries": {
        "al": "Shqipëria",
        "kv": "Kosovë"
      }
    },
    "am": {
      "lang": ["አማርኛ"],
      "countries": {
        "et": "ኢትዮጵያ"
      }
    },
    "ar": {
      "lang": ["العربية", "Arabic"],
      "countries": {
        "sa": "المملكة العربية السعودية",
        "dz": "الجزائر",
        "eg": "مصر"
      }
    },
    "an": {
      "lang": ["aragonés"],
      "countries": {
        "es": "España"
      }
    },
    "hy": {
      "lang": ["հայերեն"],
      "countries": {
        "am": "Հայաստան"
      }
    },
    "as": {
      "lang": ["অসমীয়া"],
      "countries": {
        "in": "ভাৰত"
      }
    },
    "av": {
      "lang": ["авар мацӀ", "магӀарул мацӀ"],
      "countries": {
        "ru": "Россия"
      }
    },
    "ae": {
      "lang": ["avesta"],
      "countries": {
        "ir": "ایران"
      }
    },
    "ay": {
      "lang": ["aymar aru", "aymar"],
      "countries": {
        "bo": "Bolivia",
        "pe": "Perú"
      }
    },
    "az": {
      "lang": ["azərbaycan dili"],
      "countries": {
        "az": "Azərbaycan"
      }
    },
    "bm": {
      "lang": ["bamanankan"],
      "countries": {
        "ml": "Mali"
      }
    },
    "ba": {
      "lang": ["башҡорт теле", "başqort tele"],
      "countries": {
        "ru": "Россия"
      }
    },
    "eu": {
      "lang": ["euskara"],
      "countries": {
        "es": "Euskal Herria"
      }
    },
    "be": {
      "lang": ["беларуская мова"],
      "countries": {
        "by": "Беларусь"
      }
    },
    "bn": {
      "lang": ["বাংলা"],
      "countries": {
        "bd": "বাংলাদেশ"
      }
    },
    "bh": {
      "lang": ["भोजपुरी"],
      "countries": {
        "in": "भारत"
      }
    },
    "bi": {
      "lang": ["Bislama"],
      "countries": {
        "vu": "Vanuatu"
      }
    },
    "bs": {
      "lang": ["bosanski jezik"],
      "countries": {
        "ba": "Bosna i Hercegovina"
      }
    },
    "br": {
      "lang": ["brezhoneg"],
      "countries": {
        "fr": "Breizh"
      }
    },
    "bg": {
      "lang": ["български език"],
      "countries": {
        "bg": "България"
      }
    },
    "my": {
      "lang": ["ဗမာစာ"],
      "countries": {
        "mm": "Myanmar"
      }
    },
    "ca": {
      "lang": ["Català"],
      "countries": {
        "ad": "Andorra",
        "es": "Catalunya"
      }
    },
    "ch": {
      "lang": ["Chamoru"],
      "countries": {
        "gu": "Guam"
      }
    },
    "ce": {
      "lang": ["нохчийн мотт"],
      "countries": {
        "ru": "Россия"
      }
    },
    "ny": {
      "lang": ["chiCheŵa", "chinyanja"],
      "countries": {
        "mw": "Malawi",
        "zm": "Zambia"
      }
    },
    "zh": {
      "lang": ["中文"],
      "countries": {
        "cn": "中国"
      }
    },
    "cv": {
      "lang": ["чӑваш чӗлхи"],
      "countries": {
        "ru": "Россия"
      }
    },
    "kw": {
      "lang": ["kernewek"],
      "countries": {
        "gb": "Kernow"
      }
    },
    "co": {
      "lang": ["corsu", "lingua corsa"],
      "countries": {
        "fr": "Corsica"
      }
    },
    "cr": {
      "lang": ["ᓀᐦᐃᔭᐍᐏᐣ"],
      "countries": {
        "ca": "Canada"
      }
    },
    "hr": {
      "lang": ["hrvatski jezik"],
      "countries": {
        "hr": "Hrvatska"
      }
    },
    "cs": {
      "lang": ["český jazyk"],
      "countries": {
        "cz": "Česko"
      }
    },
    "da": {
      "lang": ["dansk"],
      "countries": {
        "dk": "Danmark"
      }
    },
    "dv": {
      "lang": ["ދިވެހި"],
      "countries": {
        "mv": "މެވާއިން"
      }
    },
    "nl": {
      "lang": ["Nederlands"],
      "countries": {
        "nl": "Nederland"
      }
    },
    "dz": {
      "lang": ["རྫོང་ཁ"],
      "countries": {
        "bt": "འབྲུག་ཡུལ"
      }
    },
    "en": {
      "lang": ["English"],
      "countries": {
        "us": "United States",
        "gb": "United Kingdom",
        "au": "Australia",
        "nz": "New Zealand",
        "ca": "Canada",
        "ie": "Ireland",
        "in": "India",
        "za": "South Africa"
      }
    },
    "eo": {
      "lang": ["Esperanto"],
      "countries": {}
    },
    "et": {
      "lang": ["eesti keel"],
      "countries": {
        "ee": "Eesti"
      }
    },
    "ee": {
      "lang": ["Eʋegbe"],
      "countries": {
        "gh": "Ghana",
        "tg": "Togo"
      }
    },
    "fo": {
      "lang": ["føroyskt"],
      "countries": {
        "fo": "Føroyar"
      }
    },
    "fj": {
      "lang": ["vosa Vakaviti"],
      "countries": {
        "fj": "Fiji"
      }
    },
    "fi": {
      "lang": ["suomi", "suomen kieli"],
      "countries": {
        "fi": "Suomi"
      }
    },
    "fr": {
      "lang": ["français"],
      "countries": {
        "fr": "France",
        "ca": "Canada",
        "be": "Belgique",
        "ch": "Suisse"
      }
    },
    "ff": {
      "lang": ["Fulfulde"],
      "countries": {
        "ml": "Mali",
        "sn": "Senegal",
        "gn": "Guinée",
        "cm": "Cameroun",
        "ne": "Niger",
        "bf": "Burkina Faso",
        "ng": "Nigeria",
        "td": "Tchad",
        "gm": "Gambia"
      }
    },
    "gl": {
      "lang": ["galego"],
      "countries": {
        "es": "Galiza"
      }
    },
    "ka": {
      "lang": ["ქართული"],
      "countries": {
        "ge": "საქართველო"
      }
    },
    "de": {
      "lang": ["Deutsch"],
      "countries": {
        "de": "Deutschland",
        "at": "Österreich",
        "ch": "Schweiz"
      }
    },
    "el": {
      "lang": ["ελληνικά"],
      "countries": {
        "gr": "Ελλάδα"
      }
    },
    "gn": {
      "lang": ["Avañe'ẽ"],
      "countries": {
        "py": "Paraguay"
      }
    },
    "gu": {
      "lang": ["ગુજરાતી"],
      "countries": {
        "in": "ભારત"
      }
    },
    "ht": {
      "lang": ["Kreyòl ayisyen"],
      "countries": {
        "ht": "Ayiti"
      }
    },
    "ha": {
      "lang": ["Hausa", "هَوُسَ"],
      "countries": {
        "ne": "Nijar",
        "ng": "Najeriya",
        "cm": "Kamaru"
      }
    },
    "he": {
      "lang": ["עברית"],
      "countries": {
        "il": "ישראל"
      }
    },
    "hz": {
      "lang": ["Otjiherero"],
      "countries": {
        "na": "Namibia"
      }
    },
    "hi": {
      "lang": ["हिन्दी", "हिंदी"],
      "countries": {
        "in": "भारत"
      }
    },
    "ho": {
      "lang": ["Hiri Motu"],
      "countries": {
        "pg": "Papua New Guinea"
      }
    },
    "hu": {
      "lang": ["magyar"],
      "countries": {
        "hu": "Magyarország"
      }
    },
    "ia": {
      "lang": ["Interlingua"],
      "countries": {}
    },
    "id": {
      "lang": ["Bahasa Indonesia"],
      "countries": {
        "id": "Indonesia"
      }
    },
    "ie": {
      "lang": ["Interlingue"],
      "countries": {}
    },
    "ga": {
      "lang": ["Gaeilge"],
      "countries": {
        "ie": "Éire"
      }
    },
    "ig": {
      "lang": ["Asụsụ Igbo"],
      "countries": {
        "ng": "Nigeria"
      }
    },
    "ik": {
      "lang": ["Iñupiaq", "Iñupiatun"],
      "countries": {
        "us": "United States"
      }
    },
    "io": {
      "lang": ["Ido"],
      "countries": {}
    },
    "is": {
      "lang": ["Íslenska"],
      "countries": {
        "is": "Ísland"
      }
    },
    "it": {
      "lang": ["Italiano"],
      "countries": {
        "it": "Italia",
        "ch": "Svizzera"
      }
    },
    "iu": {
      "lang": ["ᐃᓄᒃᑎᑐᑦ"],
      "countries": {
        "ca": "Canada"
      }
    },
    "ja": {
      "lang": ["日本語"],
      "countries": {
        "jp": "日本"
      }
    },
    "jv": {
      "lang": ["ꦧꦱꦗꦮ", "Basa Jawa"],
      "countries": {
        "id": "Indonesia"
      }
    },
    "kl": {
      "lang": ["kalaallisut", "kalaallit oqaasii"],
      "countries": {
        "gl": "Kalaallit Nunaat"
      }
    },
    "kn": {
      "lang": ["ಕನ್ನಡ"],
      "countries": {
        "in": "ಭಾರತ"
      }
    },
    "kr": {
      "lang": ["Kanuri"],
      "countries": {
        "ng": "Nijeriya",
        "ne": "Najeriya",
        "cm": "Kamaru"
      }
    },
    "ks": {
      "lang": ["कश्मीरी", "كشميري"],
      "countries": {
        "in": "भारत",
        "pk": "پاکستان"
      }
    },
    "kk": {
      "lang": ["қазақ тілі"],
      "countries": {
        "kz": "Қазақстан"
      }
    },
    "km": {
      "lang": ["ភាសាខ្មែរ"],
      "countries": {
        "kh": "កម្ពុជា"
      }
    },
    "ki": {
      "lang": ["Gĩkũyũ"],
      "countries": {
        "ke": "Kenya"
      }
    },
    "rw": {
      "lang": ["Kinyarwanda"],
      "countries": {
        "rw": "Rwanda"
      }
    },
    "ky": {
      "lang": ["кыргызча", "Кыргыз тили"],
      "countries": {
        "kg": "Кыргызстан"
      }
    },
    "kv": {
      "lang": ["коми кыв"],
      "countries": {
        "ru": "Россия"
      }
    },
    "kg": {
      "lang": ["Kikongo"],
      "countries": {
        "cd": "République démocratique du Congo",
        "cg": "République du Congo"
      }
    },
    "ko": {
      "lang": ["한국어"],
      "countries": {
        "kr": "대한민국"
      }
    },
    "ku": {
      "lang": ["Kurdî", "کوردی"],
      "countries": {
        "tr": "Türkiye",
        "iq": "کوردستان"
      }
    },
    "kj": {
      "lang": ["Kuanyama"],
      "countries": {
        "na": "Namibia"
      }
    },
    "la": {
      "lang": ["lingua latīna"],
      "countries": {}
    },
    "lb": {
      "lang": ["Lëtzebuergesch"],
      "countries": {
        "lu": "Lëtzebuerg"
      }
    },
    "lg": {
      "lang": ["Luganda"],
      "countries": {
        "ug": "Uganda"
      }
    },
    "li": {
      "lang": ["Limburgs"],
      "countries": {
        "nl": "Limburg"
      }
    },
    "ln": {
      "lang": ["Lingála"],
      "countries": {
        "cd": "République démocratique du Congo"
      }
    },
    "lo": {
      "lang": ["ພາສາລາວ"],
      "countries": {
        "la": "ສປປລາວ"
      }
    },
    "lt": {
      "lang": ["lietuvių kalba"],
      "countries": {
        "lt": "Lietuva"
      }
    },
    "lu": {
      "lang": ["Tshiluba"],
      "countries": {
        "cd": "République démocratique du Congo"
      }
    },
    "lv": {
      "lang": ["latviešu valoda"],
      "countries": {
        "lv": "Latvija"
      }
    },
    "gv": {
      "lang": ["Gaelg", "Gailck"],
      "countries": {
        "gb": "Mannin"
      }
    },
    "mk": {
      "lang": ["македонски јазик"],
      "countries": {
        "mk": "Македонија"
      }
    },
    "mg": {
      "lang": ["fiteny malagasy"],
      "countries": {
        "mg": "Madagasikara"
      }
    },
    "ms": {
      "lang": ["Bahasa Melayu"],
      "countries": {
        "my": "Malaysia"
      }
    },
    "ml": {
      "lang": ["മലയാളം"],
      "countries": {
        "in": "ഇന്ത്യ"
      }
    },
    "mt": {
      "lang": ["Malti"],
      "countries": {
        "mt": "Malta"
      }
    },
    "mi": {
      "lang": ["te reo Māori"],
      "countries": {
        "nz": "Aotearoa"
      }
    },
    "mr": {
      "lang": ["मराठी"],
      "countries": {
        "in": "भारत"
      }
    },
    "mh": {
      "lang": ["Kajin M̧ajeļ"],
      "countries": {
        "mh": "M̧ajeļ"
      }
    },
    "mn": {
      "lang": ["монгол"],
      "countries": {
        "mn": "Монгол улс"
      }
    },
    "na": {
      "lang": ["Dorerin Naoero"],
      "countries": {
        "nr": "Naoero"
      }
    },
    "nv": {
      "lang": ["Diné bizaad", "Dinékʼehǰí"],
      "countries": {
        "us": "Béésh Bich'ahii Bikéyah"
      }
    },
    "nb": {
      "lang": ["Norsk Bokmål"],
      "countries": {
        "no": "Norge"
      }
    },
    "nd": {
      "lang": ["isiNdebele"],
      "countries": {
        "zw": "Zimbabwe"
      }
    },
    "ne": {
      "lang": ["नेपाली"],
      "countries": {
        "np": "नेपाल"
      }
    },
    "ng": {
      "lang": ["Owambo"],
      "countries": {
        "na": "Namibia"
      }
    },
    "nn": {
      "lang": ["Norsk Nynorsk"],
      "countries": {
        "no": "Noreg"
      }
    },
    "no": {
      "lang": ["Norsk"],
      "countries": {
        "no": "Norge"
      }
    },
    "ii": {
      "lang": ["ꆇꉙ"],
      "countries": {
        "cn": "ꍏꇩ"
      }
    },
    "nr": {
      "lang": ["isiNdebele"],
      "countries": {
        "za": "South Africa"
      }
    },
    "oc": {
      "lang": ["occitan", "lenga d'òc"],
      "countries": {
        "fr": "Occitània"
      }
    },
    "oj": {
      "lang": ["ᐊᔭᙱᐤ"],
      "countries": {
        "ca": "Canada"
      }
    },
    "cu": {
      "lang": ["ѩзыкъ словѣньскъ"],
      "countries": {}
    },
    "om": {
      "lang": ["Afaan Oromoo"],
      "countries": {
        "et": "Oromiyaa"
      }
    },
    "or": {
      "lang": ["ଓଡ଼ିଆ"],
      "countries": {
        "in": "ଭାରତ"
      }
    },
    "os": {
      "lang": ["ирон æвзаг"],
      "countries": {
        "ru": "Россия"
      }
    },
    "pa": {
      "lang": ["ਪੰਜਾਬੀ", "پنجابی"],
      "countries": {
        "in": "ਭਾਰਤ",
        "pk": "پاکستان"
      }
    },
    "pi": {
      "lang": ["पाऴि"],
      "countries": {}
    },
    "fa": {
      "lang": ["فارسی"],
      "countries": {
        "ir": "ایران",
        "af": "افغانستان"
      }
    },
    "pl": {
      "lang": ["język polski"],
      "countries": {
        "pl": "Polska"
      }
    },
    "ps": {
      "lang": ["پښتو"],
      "countries": {
        "af": "افغانستان",
        "pk": "پاکستان"
      }
    },
    "pt": {
      "lang": ["Português"],
      "countries": {
        "pt": "Portugal",
        "br": "Brasil",
        "ao": "Angola",
        "mz": "Moçambique"
      }
    },
    "qu": {
      "lang": ["Runa Simi", "Kichwa"],
      "countries": {
        "pe": "Qullasuyu"
      }
    },
    "rm": {
      "lang": ["rumantsch grischun"],
      "countries": {
        "ch": "Rumantsch"
      }
    },
    "rn": {
      "lang": ["Ikirundi"],
      "countries": {
        "bi": "Burundi"
      }
    },
    "ro": {
      "lang": ["Română"],
      "countries": {
        "ro": "România",
        "md": "Moldova"
      }
    },
    "ru": {
      "lang": ["русский язык"],
      "countries": {
        "ru": "Россия",
        "by": "Беларусь",
        "kz": "Казахстан"
      }
    },
    "sa": {
      "lang": ["संस्कृतम्"],
      "countries": {
        "in": "भारत"
      }
    },
    "sc": {
      "lang": ["sardu"],
      "countries": {
        "it": "Sardegna"
      }
    },
    "sd": {
      "lang": ["सिन्धी", "سنڌي، سندھی‎"],
      "countries": {
        "pk": "پاکستان",
        "in": "ڀارت"
      }
    },
    "se": {
      "lang": ["Davvisámegiella"],
      "countries": {
        "no": "Norga",
        "se": "Sápmi",
        "fi": "Suoma"
      }
    },
    "sm": {
      "lang": ["gagana fa'a Samoa"],
      "countries": {
        "ws": "Samoa",
        "as": "Amerika Sāmoa"
      }
    },
    "sg": {
      "lang": ["yângâ tî sängö"],
      "countries": {
        "cf": "Ködörösêse tî Bêafrîka"
      }
    },
    "sr": {
      "lang": ["српски језик"],
      "countries": {
        "rs": "Србија",
        "ba": "Босна и Херцеговина",
        "me": "Црна Гора"
      }
    },
    "gd": {
      "lang": ["Gàidhlig"],
      "countries": {
        "gb": "Alba"
      }
    },
    "sn": {
      "lang": ["chiShona"],
      "countries": {
        "zw": "Zimbabwe"
      }
    },
    "si": {
      "lang": ["සිංහල"],
      "countries": {
        "lk": "ශ්‍රී ලංකාව"
      }
    },
    "sk": {
      "lang": ["Slovenčina"],
      "countries": {
        "sk": "Slovensko"
      }
    },
    "sl": {
      "lang": ["slovenščina"],
      "countries": {
        "si": "Slovenija"
      }
    },
    "so": {
      "lang": ["Soomaaliga", "af Soomaali"],
      "countries": {
        "so": "Soomaaliya"
      }
    },
    "st": {
      "lang": ["Sesotho"],
      "countries": {
        "ls": "Lesotho",
        "za": "South Africa"
      }
    },
    "es": {
      "lang": ["español"],
      "countries": {
        "mx": "México",
        "es": "España",
        "ar": "Argentina",
        "co": "Colombia",
        "pe": "Perú",
        "ve": "Venezuela",
        "cl": "Chile",
        "ec": "Ecuador",
        "gt": "Guatemala",
        "cu": "Cuba",
        "do": "República Dominicana",
        "hn": "Honduras",
        "bo": "Bolivia",
        "cr": "Costa Rica",
        "pa": "Panamá",
        "uy": "Uruguay",
        "py": "Paraguay",
        "sv": "El Salvador",
        "ni": "Nicaragua",
        "pr": "Puerto Rico",
        "uy": "Uruguay"
      }
    },
    "su": {
      "lang": ["Basa Sunda"],
      "countries": {
        "id": "Indonesia"
      }
    },
    "sw": {
      "lang": ["Kiswahili"],
      "countries": {
        "tz": "Tanzania",
        "ke": "Kenya",
        "ug": "Uganda"
      }
    },
    "ss": {
      "lang": ["SiSwati"],
      "countries": {
        "sz": "eSwatini"
      }
    },
    "sv": {
      "lang": ["svenska"],
      "countries": {
        "se": "Sverige"
      }
    },
    "ta": {
      "lang": ["தமிழ்"],
      "countries": {
        "in": "இந்தியா",
        "lk": "இலங்கை"
      }
    },
    "te": {
      "lang": ["తెలుగు"],
      "countries": {
        "in": "భారతదేశం"
      }
    },
    "tg": {
      "lang": ["тоҷикӣ", "тоҷикӣ забон"],
      "countries": {
        "tj": "Тоҷикистон"
      }
    },
    "th": {
      "lang": ["ไทย"],
      "countries": {
        "th": "ประเทศไทย"
      }
    },
    "ti": {
      "lang": ["ትግርኛ"],
      "countries": {
        "er": "ኤርትራ",
        "et": "ኢትዮጵያ"
      }
    },
    "bo": {
      "lang": ["བོད་ཡིག"],
      "countries": {
        "cn": "རྒྱ་ནག",
        "in": "རྒྱ་ནག"
      }
    },
    "tk": {
      "lang": ["Türkmen", "Түркмен"],
      "countries": {
        "tm": "Türkmenistan"
      }
    },
    "tl": {
      "lang": ["Wikang Tagalog"],
      "countries": {
        "ph": "Pilipinas"
      }
    },
    "tn": {
      "lang": ["Setswana"],
      "countries": {
        "bw": "Botswana",
        "za": "South Africa"
      }
    },
    "to": {
      "lang": ["faka Tonga"],
      "countries": {
        "to": "Tonga"
      }
    },
    "tr": {
      "lang": ["Türkçe"],
      "countries": {
        "tr": "Türkiye"
      }
    },
    "ts": {
      "lang": ["Xitsonga"],
      "countries": {
        "za": "South Africa",
        "mz": "Mozambique"
      }
    },
    "tt": {
      "lang": ["татарча", "tatarça", "تاتارچا‎"],
      "countries": {
        "ru": "Россия"
      }
    },
    "tw": {
      "lang": ["Twi"],
      "countries": {
        "gh": "Ghana"
      }
    },
    "ty": {
      "lang": ["Reo Tahiti"],
      "countries": {
        "pf": "Polynésie française"
      }
    },
    "ug": {
      "lang": ["Uyƣurqə", "ئۇيغۇرچە‎"],
      "countries": {
        "cn": "جۇڭگو"
      }
    },
    "uk": {
      "lang": ["українська мова"],
      "countries": {
        "ua": "Україна"
      }
    },
    "ur": {
      "lang": ["اُردُو"],
      "countries": {
        "pk": "پاکستان",
        "in": "بھارت"
      }
    },
    "uz": {
      "lang": ["Oʻzbek", "Ўзбек", "أۇزبېك‎"],
      "countries": {
        "uz": "Oʻzbekiston"
      }
    },
    "ve": {
      "lang": ["Tshivenḓa"],
      "countries": {
        "za": "South Africa"
      }
    },
    "vi": {
      "lang": ["Tiếng Việt"],
      "countries": {
        "vn": "Việt Nam"
      }
    },
    "vo": {
      "lang": ["Volapük"],
      "countries": {"vo": "Vülmöpük"}
    },
    "wa": {
      "lang": ["walon"],
      "countries": {
        "be": "Wallonie"
      }
    },
    "cy": {
      "lang": ["Cymraeg"],
      "countries": {
        "gb": "Cymru"
      }
    },
    "wo": {
      "lang": ["Wollof"],
      "countries": {
        "sn": "Sénégal",
        "gm": "Gambia"
      }
    },
    "fy": {
      "lang": ["Frysk"],
      "countries": {
        "nl": "Fryslân"
      }
    },
    "xh": {
      "lang": ["isiXhosa"],
      "countries": {
        "za": "South Africa"
      }
    },
    "yi": {
      "lang": ["ייִדיש"],
      "countries": {
        "us": "‏אירתער פרייַט"
      }
    },
    "yo": {
      "lang": ["Yorùbá"],
      "countries": {
        "ng": "Nigeria"
      }
    },
    "za": {
      "lang": ["Saɯ cueŋƅ", "Saw cuengh"],
      "countries": {
        "cn": "ꑱꅃ"
      }
    },
    "zu": {
      "lang": ["isiZulu"],
      "countries": {
        "za": "South Africa"
      }
    }
  }