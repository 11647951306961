import ctx from './ctx'

export default function setState(data, value, op, metadata) {

    const path =  data

    const elem = path.split("/")
    const attr = elem.pop()

    var dat = ctx.data
    elem.forEach(e => {

        if(dat === undefined || dat[e] === undefined)
            dat[e] = {}

        dat = dat[e]
    })

    switch (op) {
        case "a": // append (default)
            //dat[attr] = getState(dat[attr] || value, value)
            dat[attr] = getState(dat[attr], value, op)
            break

        case "d": // delete
            delete dat[attr][value]
            dat[attr] = getState(dat[attr] || value)
            break

        case "s": // sum
            dat[attr] = (isNaN(dat[attr]) ? 0 : dat[attr]) + value
            break

        case null: // null
            break

        default: // ['r', '*'] = replace
            dat[attr] = getState(value)
            break
    }

    dispatchers(path, dat[attr])
    //const md = updMetadata(path, dat[attr], metadata)

    //return md
    return dat[attr]
}

function dispatchers(path, value) {

    ctx.dispatchers[path] && Object.keys(ctx.dispatchers[path]).forEach( id => {
        //console.log("DISP:", path, id, value)
        ctx.dispatchers[path][id](value)
    })
}

function getState(state, value, type) {

    //console.log("SI", state, value)
    return (
            (Array.isArray(state) && (value !== undefined ? [...state, ...(Array.isArray(value) ? value: [value])] : [...state]))
        ||  (typeof(state) === 'object' && {...state, ...value})
        ||  ((typeof(state) === 'string' && type === "a") ? state + value : value)
        ||  (typeof(state) === 'boolean'
                ? value 
                : value || state
            )
    )
}

    /*

    function updState(obj1, obj2) {
        console.log("si")

        Object.entries(obj2).map(([key, value]) => {
            obj1[key] = value
        })
        //{...state, ...value}
    }
    switch (op) {
        case "a": // append (default)
            ctx.data[data] = getState(ctx.data[data] || value, value)
            break

        case "d": // delete
            delete ctx.data[data][value]
            ctx.data[data] = getState(ctx.data[data] || value)
            break

        default: // ['r', '*'] = replace
            ctx.data[data] = getState(value)
            break
    }
    */
