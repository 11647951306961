import { isIPhone, isMac } from "../commons/navigator"

let transitionPadding = "transition-all duration-500 ease-in-out"

let mbl ={
    px: 3,
    tw: "px-3 md:px-3"
}

let pc = {
    px:4,
    tw: "pt-6 pb-6 md:px-6"
}

let header = {
    height: 16,
    tw: "h-16 pt-16 pb-16"
}

let footer = {
    height: 12,
    tw: "pt-12 pb-12 h-12"
}

let assets = {
    openWidth: 96,
    tw: "pr-96 w-96",
}

let conversationPb = ""
let assetsPosition = ""
if(isIPhone()) {
    conversationPb = "pb-16"
    assetsPosition = "//fixed //top-0 //right-0 !w-full scroll-y-auto"
} else if(isMac()) {
    conversationPb = "pb-16"
} else {
    conversationPb = "pb-16"
}

export default {
    data:{
        mode:"chat",
        assets:{},
        ui:{
            main:{
                //bg: "bg-slate-800",
                bg: "bg-blue-100",
            },
            conversation:{
                px:`px-${mbl.px} md:px-${pc.px}`,
                py:`pt-${header.height} ${conversationPb}`,
            },
            avatar:{
                maximized: true,
                width: `w-40`,
                height: `h-40`,
            },
            header:{
                minHeight: `h-${header.height}`,
                maxHeight: `h-24`,
                tw:`pr-0`
            },
            footer:{
                height: `h-${footer.height}`,
            },
            assets:{
                switcher:'preview',
                transition: transitionPadding,
                className: {
                    open: `bg-black ${assetsPosition} w-${assets.openWidth}`,
                    preview: `relative w-14 md:w-16`,
                    closed: `relative w-0`,
                },
                resize: {
                    open:`pr-${assets.openWidth} ${transitionPadding}`,
                    preview:`pr-0 ${transitionPadding}`,
                    closed:`pr-0 ${transitionPadding}`,
                }
            },
        },
    },
    dispatchers:{

    },
    sockets:{
        default:{
            path:'sk', // path to save data in ctx (example: `ctx.data.sk`). Default = ''
            url: 'wss://reh6ekw7a4.execute-api.us-east-1.amazonaws.com/dev', //'wss://etvifwplth.execute-api.us-east-1.amazonaws.com/dev',
        }
    }
}