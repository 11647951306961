export default function CloseIcon({ className, onClick }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            className={"hover:text-red-400 " + className}
            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
            onClick={onClick}
        >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    );
}