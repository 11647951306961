import useData from "../vio/context/useData"
import useEmit from "../vio/context/useEmit"

const useTw = () => ({
    root:`
        absolute bottom-1 right-2
        px-4 py-1
        rounded
        bg-red-900
        text-red-400
    `,
    a:`
        text-gray-400
    `,
})

export default function Error() {

    const tw = useTw()
    const { data={} } = useEmit("error")
    const { code, error } = data

    return( code &&
        <div className={tw.root}>
            {`Widget Error [${code}]: ${error}.`}&nbsp;
            <a href='#' className={tw.a}>[More Details...]</a>
        </div>
    )
}
