import { useEffect } from "react";
import ctx from "./ctx";
import useData from "./useData"

export default function useEmit(name, props={}) {

    const [data, setData] = useData(name, `useEmit${name}`)
    const skt = ctx.sockets.default
    const socket = {
        ...skt,
        reply: (data={}) => skt.emit(name, data)
    }

    useEffect(() => {
        const { onload, actions, type="r" } = props

        if(actions)
            skt.actions = { ...skt.actions, ...actions }

        setData(undefined, type)
        onload && onload(socket)
    },[])

    return {data, socket, setData}
}